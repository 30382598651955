import { useFormikContext } from "formik";
import { FC, useContext, useState } from "react";
import appStyles from "../../App.module.css";
import appConstants, { formatAmount } from "../../config/app-constants";
import { ISingleTripQuoteData } from "../../config/interfaces";
import { ISingleTripQuoteContext, SingleTripQuoteContext } from "../TripQuote";
import { IQuoteDetailForm } from "../steps/QuoteDetail";
interface ExcessFeeProps {}

const ExcessFee: FC<ExcessFeeProps> = (props: ExcessFeeProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const { quoteData, setQuoteData, tripType } = useContext(
    SingleTripQuoteContext
  ) as ISingleTripQuoteContext;
  const { values, setFieldValue } = useFormikContext<IQuoteDetailForm>();

  const onValueChange = (event: any) => {
    setFieldValue("excess_fee", parseInt(event.target.value));
    const _quoteData: ISingleTripQuoteData = { ...quoteData };
    _quoteData.excess_fee = parseInt(event.target.value);
    setQuoteData(_quoteData);
  };

  return (
    <div
      className={`${appStyles.collapseBlock} ${
        values.excess_fee !== 0 ? appStyles.collapseBlockPackageAdded : ""
      }`}
    >
      <div className={appStyles.collapseHeader}>
        <div
          className={appStyles.collapseHeaderTitle}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M9 0.25C13.957 0.25 18 4.29297 18 9.25C18 14.2422 13.957 18.25 9 18.25C4.00781 18.25 0 14.2422 0 9.25C0 4.29297 4.00781 0.25 9 0.25ZM9 14.3125C9.59766 14.3125 10.125 13.8203 10.125 13.1875C10.125 12.5547 9.59766 12.0625 9 12.0625C8.33203 12.0625 7.875 12.5547 7.875 13.1875C7.875 13.8203 8.36719 14.3125 9 14.3125ZM11.4258 9.32031C12.1992 8.86328 12.6562 8.05469 12.6562 7.21094C12.6562 5.83984 11.5664 4.75 10.125 4.75H8.33203C6.99609 4.75 5.90625 5.83984 5.90625 7.21094C5.90625 7.66797 6.29297 8.05469 6.75 8.05469C7.20703 8.05469 7.59375 7.66797 7.59375 7.21094C7.59375 6.78906 7.91016 6.4375 8.36719 6.4375H10.1602C10.582 6.4375 10.9688 6.78906 10.9688 7.21094C10.9688 7.49219 10.8281 7.73828 10.582 7.87891L8.57812 9.07422C8.29688 9.25 8.15625 9.53125 8.15625 9.8125V10.375C8.15625 10.832 8.54297 11.2188 9 11.2188C9.45703 11.2188 9.84375 10.832 9.84375 10.375V10.3047L11.4258 9.32031Z'
              fill='black'
            />
          </svg>
          <p>Excess for baggage and money</p>
        </div>
        <div className={appStyles.collapseHeaderPrice}>
          <h6>
            <div className={`${appStyles.bfRadio} ${appStyles.bfRadioInline}`}>
              <label>
                <input
                  type='radio'
                  value={
                    tripType === appConstants.ST
                      ? appConstants.excessFee1
                      : appConstants.multiExcessFee1
                  }
                  name='excess_fee'
                  onChange={onValueChange}
                  checked={
                    values.excess_fee ===
                    (tripType === appConstants.ST
                      ? appConstants.excessFee1
                      : appConstants.multiExcessFee1)
                  }
                />
                <span className={appStyles.bfRadiomark}></span>
                {formatAmount(
                  tripType === appConstants.ST
                    ? appConstants.excessFee1
                    : appConstants.multiExcessFee1,
                  quoteData?.host_country?.code || "US",
                  quoteData.currency?.value || "GBP",
                  0,
                  0
                )}
              </label>
            </div>

            <div className={`${appStyles.bfRadio} ${appStyles.bfRadioInline}`}>
              <label>
                <input
                  type='radio'
                  value={
                    tripType === appConstants.ST
                      ? appConstants.excessFee2
                      : appConstants.multiExcessFee2
                  }
                  name='excess_fee'
                  onChange={onValueChange}
                  checked={
                    values.excess_fee ===
                    (tripType === appConstants.ST
                      ? appConstants.excessFee2
                      : appConstants.multiExcessFee2)
                  }
                />
                <span className={appStyles.bfRadiomark}></span>
                {formatAmount(
                  tripType === appConstants.ST
                    ? appConstants.excessFee2
                    : appConstants.multiExcessFee2,
                  quoteData?.host_country?.code || "US",
                  quoteData.currency?.value || "GBP",
                  0,
                  0
                )}
              </label>
            </div>
          </h6>
        </div>
      </div>
      {expanded && (
        <div className={appStyles.collapseBody}>
          <h6>
            The amount You have to pay towards making a claim.
          </h6>
        </div>
      )}
    </div>
  );
};

export default ExcessFee;
