import { FC, useContext, useEffect, useState } from "react";
import appStyles from "../../App.module.css";
import QuoteApi from "../../api/quote";
import { formatDate } from "../../config/app-constants";
import { ISingleTripQuoteContext, SingleTripQuoteContext } from "../TripQuote";
import BannerAdImage from "../common/BannerAdImage";
import Footer from "../common/Footer";
import HeaderImage from "../common/HeaderImage";
import OrderSummary from "../common/OrderSummary";
import ForwardArrow from "../icons/ForwardArrow";

interface OrderDetailProps {}

const OrderDetail: FC<OrderDetailProps> = (props: OrderDetailProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isdownloading, setIsDownLoading] = useState<boolean>(false);

  const { order, restart } = useContext(
    SingleTripQuoteContext
  ) as ISingleTripQuoteContext;

  useEffect(() => {
    // init();
  }, []);

  // const init = async () => {
  //     try {
  //         const response: any = await QuoteApi.successProcessPayment(order.order_number);
  //         console.log(response.data);
  //     } catch (e) {
  //         console.log(e);
  //     } finally {
  //         setLoading(false);
  //     }
  // };

  const handleViewPolicy = async (policyNumber: string) => {
    try {
      setIsDownLoading(true);
      const response: any = await QuoteApi.getPolicyFiles(policyNumber);
      // console.log(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsDownLoading(false);
    }
  };

  return (
    <div className={`${appStyles.mainContainer} ${appStyles.W600}`}>
      <div className={`${appStyles.quotePaymentWrapper}`}>
        <div className={`${appStyles.headerWrapper}`}>
          <div
            className={`${appStyles.pageTitle} ${appStyles.pageTitleCongrats}`}
          >
            <h1 className={`${appStyles.colorSuccess}`}>
              Congratulations! <br />
              You’re covered
            </h1>
          </div>
          <HeaderImage />
        </div>
        <div
          className={`${appStyles.pageTitle} ${appStyles.pageTitleCongrats}`}
        >
          <p>
            Check your inbox for an email with your policy documents and related
            information.
          </p>
        </div>
        <div
          className={`${appStyles.tripSummaryRow} ${appStyles.tripSummaryRowOrderInfo}`}
        >
          <div className={appStyles.tripSummaryLabel}>
            Order No: {order?.order_number}
          </div>
          <div className={appStyles.tripSummaryLabel}>
            Order Date: {formatDate(new Date(order?.ordered_at))}
          </div>
        </div>

        <OrderSummary />

        {/* TODO: for now, api is not ready so commented */}
        {/* <div className={appStyles.formRow}>
                    <div
                        className={`${appStyles.buttonBlock} ${appStyles.buttonBlockNextPrev} ${appStyles.buttonBlockAlignCenter}`}
                    >
                        <a
                            href='#'
                            className={`${appStyles.bfLinkButton}`}
                            onClick={(e) => {
                                e.preventDefault();
                                window.print();
                            }}
                        >
                            <svg
                                width='12'
                                height='13'
                                viewBox='0 0 12 13'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path d='M10.5 5C11.3203 5 12 5.67969 12 6.5V8.75C12 9.17188 11.6484 9.5 11.25 9.5H10.5V11.75C10.5 12.1719 10.1484 12.5 9.75 12.5H2.25C1.82812 12.5 1.5 12.1719 1.5 11.75V9.5H0.75C0.328125 9.5 0 9.17188 0 8.75V6.5C0 5.67969 0.65625 5 1.5 5H10.5ZM9 11V8.75H3V11H9ZM10.125 7.4375C10.4297 7.4375 10.6875 7.20312 10.6875 6.875C10.6875 6.57031 10.4297 6.3125 10.125 6.3125C9.79688 6.3125 9.5625 6.57031 9.5625 6.875C9.5625 7.20312 9.79688 7.4375 10.125 7.4375ZM3 2V4.25H1.5V1.25C1.5 0.851562 1.82812 0.5 2.25 0.5H8.67188C8.88281 0.5 9.07031 0.59375 9.21094 0.734375L10.2656 1.78906C10.4062 1.92969 10.5 2.11719 10.5 2.32812V4.25H9V2.63281L8.36719 2H3Z' />
                            </svg>
                            Print
                        </a>
                    </div>
                </div> */}
        <div className={`${appStyles.pageSubTitle} ${appStyles.bfMarginTop}`}>
          <h2 className={appStyles.colorPrimary}>Your policy</h2>
        </div>
        <div
          className={`${appStyles.quoteFormBlock} ${appStyles.quoteFormPolicyInformation}`}
        >
          <div className={`${appStyles.tripSummaryRow}`}>
            <div className={`${appStyles.tripPolicyInfo}`}>
              <b className={`${appStyles.tripPolicyPerson}`}>
                {`${order?.applicant?.first_name || ""} ${
                  order?.applicant?.middle_name || ""
                } ${order?.applicant?.last_name}` || ""}
              </b>
              <span className={appStyles.tripSummaryLabel}>Policy #</span>
              <b>{order?.items[0]?.policy_number}</b>
            </div>
            {/* TODO: for now, api is not ready so commented */}
            {/* <div className={appStyles.tripSummaryValue}>
                            <button
                                className={`${appStyles.bfButton} ${appStyles.bfButtonPrimary}`}
                                type='button'
                                onClick={() => handleViewPolicy(order?.items[0]?.policy_number)}
                            >
                                View Policy
                            </button>
                        </div> */}
          </div>
        </div>
        <Footer />
        <BannerAdImage />
        <div className={appStyles.formRow}>
          <div
            className={`${appStyles.buttonBlock} ${appStyles.buttonBlockNextPrev} ${appStyles.buttonBlockAlignCenter}`}
          >
            <button
              className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconAfter} ${appStyles.bfButtonOutline}`}
              type='button'
              onClick={(e) => {
                restart();
              }}
            >
              New Quote
              <span
                className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}
              >
                <ForwardArrow />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
