import React from "react";
import appStyles from "../../App.module.css";
interface PaymentIconsProps {}

const PaymentIcons: React.FC<PaymentIconsProps> = (props: PaymentIconsProps) => {
	return (
		<div className={`${appStyles.paymentIconsBlock}`}>
			<svg width='33' height='10' viewBox='0 0 33 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M16.8929 3.19484C16.8778 4.58453 18.2011 5.36533 19.1992 5.82378C20.2276 6.29656 20.5755 6.60459 20.5679 7.02722C20.5603 7.67908 19.7512 7.95845 18.9875 7.97278C17.6642 7.99427 16.8929 7.6361 16.2804 7.3639L15.804 9.48424C16.4165 9.74928 17.5583 9.98567 18.738 10C21.5131 10 23.3204 8.70344 23.3355 6.69055C23.3431 4.1404 19.6076 3.99714 19.6378 2.85817C19.6454 2.51433 19.9932 2.14183 20.7569 2.04871C21.135 1.99857 22.1785 1.96275 23.3582 2.47851L23.8194 0.429799C23.1843 0.207736 22.3676 0 21.3468 0C18.7455 0 16.908 1.31805 16.8929 3.19484ZM28.2809 0.179083C27.7742 0.179083 27.3508 0.458453 27.1542 0.888253L23.1918 9.84241H25.9594L26.5114 8.40258H29.8991L30.2167 9.84241H32.6667L30.5343 0.179083H28.2809ZM28.6665 2.7937L29.4681 6.4255H27.2752L28.6665 2.7937ZM13.5355 0.179083L11.3502 9.84241H13.9892L16.1745 0.179083H13.5355ZM9.63364 0.179083L6.88117 6.76218L5.7696 1.16762C5.64105 0.544413 5.12685 0.179083 4.55216 0.179083H0.0604941L0 0.458453C0.922531 0.6447 1.96605 0.952723 2.60123 1.28224C2.98688 1.48281 3.10031 1.65473 3.22886 2.13467L5.33102 9.84957H8.1213L12.4012 0.179083H9.63364Z'
					fill='#1A1F71'
				/>
			</svg>
			<svg width='22' height='11' viewBox='0 0 22 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g clipPath='url(#clip0_1978_185)'>
					<path
						d='M11.2418 2.25234C11.2307 3.22839 12.1131 3.77313 12.7784 4.09821C13.4619 4.43041 13.691 4.64371 13.6886 4.94106C13.6839 5.39617 13.1431 5.59681 12.6388 5.60472C11.7563 5.619 11.2441 5.36686 10.8357 5.17655L10.5178 6.66281C10.9278 6.85115 11.6842 7.01487 12.4699 7.02319C14.3126 7.02319 15.5186 6.11215 15.525 4.70238C15.5322 2.91204 13.0488 2.8133 13.0662 2.01369C13.071 1.76987 13.3033 1.51139 13.81 1.44517C14.0621 1.41229 14.7543 1.38691 15.5409 1.74889L15.8485 0.310523C15.4266 0.156714 14.8827 0.0096351 14.2072 0.0096351C12.4731 0.0096351 11.2521 0.931776 11.2418 2.25234ZM18.8131 0.133697C18.4769 0.133697 18.1923 0.329587 18.0662 0.631272L15.4338 6.91696H17.2757L17.642 5.90442H19.893L20.1047 6.91696H21.7278L20.3117 0.133697H18.8131ZM19.0708 1.96609L19.6021 4.51369H18.1471L19.0708 1.96609ZM9.00891 0.133697L7.55794 6.91696H9.31263L10.7637 0.133697H9.00891ZM6.41258 0.133697L4.58657 4.75075L3.84757 0.825155C3.76116 0.38666 3.41902 0.133697 3.03842 0.133697H0.0523518L0.0107422 0.330767C0.623614 0.463947 1.32024 0.678429 1.74162 0.907578C2.00013 1.04793 2.07384 1.17004 2.1591 1.50348L3.55777 6.91696H5.41315L8.25648 0.133697H6.41258Z'
						fill='url(#paint0_linear_1978_185)'
					/>
					<path
						d='M13.7729 8.65098V10.9353C13.9248 10.9524 14.1008 10.9626 14.3184 10.9626C14.7704 10.9626 15.1292 10.8453 15.35 10.6281C15.5637 10.414 15.6846 10.1036 15.6846 9.72418C15.6846 9.34793 15.5637 9.07873 15.3531 8.88925C15.1498 8.70292 14.8425 8.59945 14.4116 8.59945C14.1733 8.59945 13.9561 8.62005 13.7729 8.65098ZM14.0734 10.7074V8.86826C14.1527 8.85121 14.2701 8.83731 14.4251 8.83731C15.0602 8.83731 15.3706 9.1858 15.367 9.73448C15.367 10.3624 15.0221 10.7212 14.3767 10.7212C14.2598 10.7212 14.1491 10.7177 14.0734 10.7074Z'
						fill='url(#paint1_linear_1978_185)'
					/>
					<path
						d='M16.0405 10.1345C16.0405 10.6384 16.3474 10.9801 16.8616 10.9801C17.1272 10.9801 17.3135 10.9214 17.4206 10.8731L17.3655 10.6558C17.2549 10.7038 17.1205 10.7419 16.9032 10.7419C16.596 10.7419 16.3371 10.573 16.3303 10.1623H17.5034C17.5101 10.1278 17.5137 10.083 17.5137 10.0207C17.5137 9.71387 17.3722 9.23772 16.8235 9.23772C16.3371 9.23772 16.0405 9.63101 16.0405 10.1345ZM16.7994 9.45498C17.1411 9.45498 17.224 9.75507 17.2204 9.945H16.3335C16.3577 9.73447 16.4889 9.45498 16.7994 9.45498Z'
						fill='url(#paint2_linear_1978_185)'
					/>
					<path
						d='M17.9759 8.49241V10.5107C17.9759 10.659 17.9691 10.8283 17.9624 10.9421H18.2209L18.2383 10.6693H18.2451C18.3727 10.8869 18.5662 10.9801 18.8076 10.9801C19.1803 10.9801 19.5597 10.6832 19.5597 10.0865C19.5597 9.58261 19.2699 9.23772 18.8524 9.23772C18.5832 9.23772 18.3902 9.35505 18.2831 9.54141H18.2764V8.49241H17.9759ZM18.2898 10.3831C18.2796 10.3486 18.2764 10.3105 18.2764 10.2729V9.97589C18.2764 9.92753 18.2831 9.88633 18.2934 9.84823C18.3557 9.62071 18.5487 9.4756 18.7525 9.4756C19.08 9.4756 19.2524 9.76221 19.2524 10.0968C19.2524 10.4798 19.0665 10.7387 18.7454 10.7387C18.5246 10.7387 18.3454 10.5936 18.2898 10.3831Z'
						fill='url(#paint3_linear_1978_185)'
					/>
					<path
						d='M19.9878 8.80284C19.9878 8.90633 20.0599 8.9892 20.1706 8.9892C20.291 8.9892 20.3636 8.90633 20.3636 8.80284C20.3636 8.69584 20.2879 8.61297 20.1773 8.61297C20.0635 8.61297 19.9878 8.69941 19.9878 8.80284ZM20.0254 10.9421H20.3292V9.27226H20.0254V10.9421Z'
						fill='url(#paint4_linear_1978_185)'
					/>
					<path
						d='M20.9885 8.96141V9.27222H20.73V9.50334H20.9885V10.414C20.9885 10.6107 21.023 10.7593 21.1058 10.8525C21.1784 10.9318 21.2922 10.9801 21.4337 10.9801C21.551 10.9801 21.6442 10.9595 21.7029 10.9385L21.689 10.7074C21.6442 10.7212 21.5958 10.728 21.5129 10.728C21.3441 10.728 21.2854 10.6107 21.2854 10.4037V9.50334H21.7199V9.27222H21.2854V8.87182L20.9885 8.96141Z'
						fill='url(#paint5_linear_1978_185)'
					/>
				</g>
				<defs>
					<linearGradient
						id='paint0_linear_1978_185'
						x1='0.0107448'
						y1='3.51641'
						x2='21.7278'
						y2='3.51641'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#2C244A' />
						<stop offset='0.0540673' stopColor='#2C244A' />
						<stop offset='0.360259' stopColor='#262B53' />
						<stop offset='0.683381' stopColor='#1E3D7D' />
						<stop offset='1' stopColor='#1D1D38' />
					</linearGradient>
					<linearGradient
						id='paint1_linear_1978_185'
						x1='13.7729'
						y1='9.78107'
						x2='15.6846'
						y2='9.78107'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#2C244A' />
						<stop offset='0.0540673' stopColor='#2C244A' />
						<stop offset='0.360259' stopColor='#262B53' />
						<stop offset='0.683381' stopColor='#1E3D7D' />
						<stop offset='1' stopColor='#1D1D38' />
					</linearGradient>
					<linearGradient
						id='paint2_linear_1978_185'
						x1='16.0405'
						y1='10.1089'
						x2='17.5137'
						y2='10.1089'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#2C244A' />
						<stop offset='0.0540673' stopColor='#2C244A' />
						<stop offset='0.360259' stopColor='#262B53' />
						<stop offset='0.683381' stopColor='#1E3D7D' />
						<stop offset='1' stopColor='#1D1D38' />
					</linearGradient>
					<linearGradient
						id='paint3_linear_1978_185'
						x1='17.9624'
						y1='9.73626'
						x2='19.5597'
						y2='9.73626'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#2C244A' />
						<stop offset='0.0540673' stopColor='#2C244A' />
						<stop offset='0.360259' stopColor='#262B53' />
						<stop offset='0.683381' stopColor='#1E3D7D' />
						<stop offset='1' stopColor='#1D1D38' />
					</linearGradient>
					<linearGradient
						id='paint4_linear_1978_185'
						x1='19.9878'
						y1='9.77755'
						x2='20.3636'
						y2='9.77755'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#2C244A' />
						<stop offset='0.0540673' stopColor='#2C244A' />
						<stop offset='0.360259' stopColor='#262B53' />
						<stop offset='0.683381' stopColor='#1E3D7D' />
						<stop offset='1' stopColor='#1D1D38' />
					</linearGradient>
					<linearGradient
						id='paint5_linear_1978_185'
						x1='20.73'
						y1='9.92596'
						x2='21.7199'
						y2='9.92596'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#2C244A' />
						<stop offset='0.0540673' stopColor='#2C244A' />
						<stop offset='0.360259' stopColor='#262B53' />
						<stop offset='0.683381' stopColor='#1E3D7D' />
						<stop offset='1' stopColor='#1D1D38' />
					</linearGradient>
					<clipPath id='clip0_1978_185'>
						<rect width='21.7344' height='11' fill='white' />
					</clipPath>
				</defs>
			</svg>
			<svg width='17' height='11' viewBox='0 0 17 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M5.87402 9.91252H10.2294V2.05017H5.87402V9.91252Z'
					fill='#EB611C'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M6.15076 5.98315C6.15076 4.38811 6.89366 2.96786 8.05171 2.0538C7.20321 1.38374 6.1362 0.983154 4.97451 0.983154C2.2287 0.983154 0 3.21913 0 5.98315C0 8.74717 2.2287 10.9832 4.97815 10.9832C6.13984 10.9832 7.20685 10.5826 8.05535 9.91251C6.89366 8.99845 6.15076 7.5782 6.15076 5.98315Z'
					fill='#E41221'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M15.9505 9.07857V8.88556H15.8995L15.8413 9.0203L15.783 8.88556H15.732V9.07857H15.7684V8.9329L15.8231 9.06036H15.8595L15.9141 8.9329V9.07857H15.9505ZM15.6301 9.07857V8.91833H15.6956V8.88556H15.5317V8.91833H15.5973V9.07857H15.6301ZM16.1071 5.98315C16.1071 8.74353 13.8784 10.9832 11.129 10.9832C9.96727 10.9832 8.90026 10.5826 8.05176 9.91251C9.20981 8.99845 9.9527 7.5782 9.9527 5.98315C9.9527 4.38811 9.20981 2.96786 8.05176 2.0538C8.89662 1.38374 9.96727 0.983154 11.129 0.983154C13.8784 0.983154 16.1071 3.21913 16.1071 5.98315Z'
					fill='#F59E1E'
				/>
			</svg>
		</div>
	);
};

export default PaymentIcons;
