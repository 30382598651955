import { FC } from "react";
import appStyles from "../App.module.css";
import ClearBorderCircle from "./icons/ClearBorderCircle";

interface PopupProps {
  title: string;
  onClose: () => void;
  children: JSX.Element;
}

const Popup: FC<PopupProps> = (props: PopupProps) => {
  const { title, onClose, children } = props;

  return (
    <div className={appStyles.battlefacePluginModal}>
      <div className={appStyles.battlefacePluginModalOverlay}></div>
      <div className={appStyles.battlefacePluginModalContent}>
        <p
          className={appStyles.battlefacePluginModalClose}
          onClick={() => onClose()}
        >
          <ClearBorderCircle width={30} height={30} />
        </p>

        <div className={appStyles.battlefacePluginModalBody}>
          <h2 className={`${appStyles.textCenter} ${appStyles.colorPrimary}`}>
            {title}
          </h2>

          <div className={appStyles.battlefacePluginModalContentData}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
