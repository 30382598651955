import { FC, useContext, useLayoutEffect } from "react";
import { ISingleTripQuoteContext, SingleTripQuoteContext } from "./TripQuote";
import QuoteDetail from "./steps/QuoteDetail";
import QuoteForm from "./steps/QuoteForm";
import OrderForm from "./steps/OrderForm";
import PaymentForm from "./steps/PaymentForm";
import OrderDetail from "./steps/OrderDetail";

interface StepProps {}

const Step: FC<StepProps> = (props: StepProps) => {
    const { currentStep } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

    let stepContent: any;

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStep]);

    switch (currentStep) {
        case 0:
            stepContent = <QuoteForm />;
            break;
        case 1:
            stepContent = <QuoteDetail />;
            break;
        case 2:
            stepContent = <OrderForm />;
            break;
        case 3:
            stepContent = <PaymentForm />;
            break;
        case 4:
            stepContent = <OrderDetail />;
            break;
        default:
            stepContent = <QuoteForm />;
            break;
    }

    return stepContent;
};

export default Step;
