import { FC } from "react";

interface BackArrowProps {}

const BackArrow: FC<BackArrowProps> = (props: BackArrowProps) => {
  return (
    <svg
      width='14'
      height='11'
      viewBox='0 0 14 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M13.0977 5.75C13.0977 6.24219 12.7148 6.625 12.25 6.625H3.85547L6.72656 9.52344C7.08203 9.85156 7.08203 10.4258 6.72656 10.7539C6.5625 10.918 6.34375 11 6.125 11C5.87891 11 5.66016 10.918 5.49609 10.7539L1.12109 6.37891C0.765625 6.05078 0.765625 5.47656 1.12109 5.14844L5.49609 0.773438C5.82422 0.417969 6.39844 0.417969 6.72656 0.773438C7.08203 1.10156 7.08203 1.67578 6.72656 2.00391L3.85547 4.875H12.25C12.7148 4.875 13.0977 5.28516 13.0977 5.75Z' />
    </svg>
  );
};

export default BackArrow;
