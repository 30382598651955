import { useContext, useState } from "react";

import appStyles from "../../App.module.css";
import appConstants, {
  formatAmount,
  formatDate,
} from "../../config/app-constants";
import { ISingleTripQuoteContext, SingleTripQuoteContext } from "../TripQuote";
import PackageDisplayName from "./PackageDisplayName";

function OrderSummary() {
  const { quoteData, order, tripType, quoteResult, defaultPackages } =
    useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

  const [showTripSummary, setShowTripSummary] = useState<boolean>(true);
  const subTotal: number = order?.premium || 0;
  const fees: number = order?.items[0]?.order?.fees || 0;
  const tax: number = order?.tax || 0;
  const total: number = order?.total || 0;

  // Only those are considered which are not part of default pakcages and the amount is  different from the default package list
  const benefitsAdded: any = quoteResult?.details?.filter(
    (pkg: any) =>
      pkg.type !== "level" &&
      pkg.package_alias !== "base_package" &&
      (!defaultPackages[pkg.package_alias] ||
        pkg.amount - defaultPackages[pkg.package_alias]?.amount !== 0)
  );

  const _defaultPackages: any = Object.values(defaultPackages) || [];

  return (
    <div
      className={`${appStyles.tripSummary}  ${
        showTripSummary ? appStyles.tripSummaryVisible : ""
      }`}
    >
      <div
        className={`${appStyles.tripSummaryTitle} ${appStyles.tripSummaryAlwaysOpen}`}
        onClick={(e) => {
          setShowTripSummary(showTripSummary);
        }}
      >
        <h4>Order Summary</h4>
      </div>
      <div className={`${appStyles.tripSummaryContent}`}>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>Plan</div>
          <div className={appStyles.tripSummaryValue}>
            {tripType === appConstants.ST ? "Single Trip" : "Annual Multi-Trip"}
          </div>
        </div>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>Number of travellers</div>
          <div className={appStyles.tripSummaryValue}>
            {order?.items?.length || 0}
          </div>
        </div>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>
            {tripType === appConstants.AMT ? "Cover Duration" : "Plan duration"}
          </div>
          <div className={appStyles.tripSummaryValue}>
            {formatDate(new Date(order?.items[0]?.start_at))} to{" "}
            {formatDate(new Date(order?.items[0]?.end_at))}
          </div>
        </div>
        {/* for maximum trip Duration For Amt  */}
        {tripType === appConstants.AMT && (
          <div className={`${appStyles.tripSummaryRow}`}>
            <div className={appStyles.tripSummaryLabel}>
              {quoteData.packages["base_package"].package.name}
            </div>
            <div className={appStyles.tripSummaryValue}>
              {quoteData.packages["base_package"].level?.level}
            </div>
          </div>
        )}

        <div className={`${appStyles.tripSummaryOrderBreakdown}`}>
          <div className={`${appStyles.tripSummaryRow}`}>
            <div className={appStyles.tripSummaryLabel}>Subtotal</div>
            <div className={appStyles.tripSummaryValue}>
              {formatAmount(
                subTotal,
                quoteData.host_country?.code || "US",
                quoteData.currency?.value || "GBP"
              )}
            </div>
          </div>
          {/** Base cover is calculated based on the sum of default packages  */}
          <div className={`${appStyles.tripSummaryRow}`}>
            <div className={`${appStyles.tripSummaryLabel} ${appStyles.pL15}`}>
              Base Cover
            </div>
            <div className={appStyles.tripSummaryValue}>
              {formatAmount(
                _defaultPackages
                  ?.map((pkg: any) => pkg?.amount || 0)
                  ?.reduce(
                    (total: number = 0, amount: number) => total + amount
                  ),
                quoteData.host_country?.code || "US",
                quoteData.currency?.value || "GBP"
              )}
            </div>
          </div>

          {benefitsAdded.map((result: any, index: number) => {
            return (
              <div key={index} className={`${appStyles.tripSummaryRow}`}>
                <div
                  className={`${appStyles.tripSummaryLabel} ${appStyles.pL15}`}
                >
                  <PackageDisplayName packageName={result.type} />
                </div>
                <div className={appStyles.tripSummaryValue}>
                  {formatAmount(
                    result.amount -
                      (defaultPackages[result.package_alias]?.amount || 0),
                    quoteData.host_country?.code || "US",
                    quoteData.currency?.value || "GBP"
                  )}
                </div>
              </div>
            );
          })}

          <div className={`${appStyles.tripSummaryRow}`}>
            <div className={appStyles.tripSummaryLabel}>Tax</div>
            <div className={appStyles.tripSummaryValue}>
              {formatAmount(
                tax,
                quoteData.host_country?.code || "US",
                quoteData.currency?.value || "GBP"
              )}
            </div>
          </div>
          <div className={`${appStyles.tripSummaryRow}`}>
            <div className={appStyles.tripSummaryLabel}>Fees</div>
            <div className={appStyles.tripSummaryValue}>
              {formatAmount(
                fees,
                quoteData.host_country?.code || "US",
                quoteData.currency?.value || "GBP"
              )}
            </div>
          </div>
          <div
            className={`${appStyles.tripSummaryRow} ${appStyles.tripSummaryOrderTotal}`}
          >
            <div className={appStyles.tripSummaryLabel}>Total</div>
            <div
              className={`${appStyles.tripSummaryValue} ${appStyles.colorPrimary}`}
            >
              {formatAmount(
                total,
                quoteData.host_country?.code || "US",
                quoteData.currency?.value || "GBP"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
