import { FC } from "react";

interface InclusionAndExclusionsProps {
  groupsData: any;
  selectedGroups: any;
}

const InclusionAndExclusionsPopup: FC<InclusionAndExclusionsProps> = (
  props: InclusionAndExclusionsProps
) => {
  const { groupsData, selectedGroups } = props;

  return (
    <>
      <b>Included countries: </b>
      {groupsData[selectedGroups[0]?.name]?.countries?.join(", ") || ""}
      <br />
      <br />
      <b>Excluded countries: </b>
      {groupsData[selectedGroups[0]?.name]?.excluded?.join(", ") || ""}
    </>
  );
};

export default InclusionAndExclusionsPopup;
