import { FC, useContext } from "react";
import appStyles from "../../App.module.css";
import appConstants, {
  formatAmount,
  privacyPolicyDocs,
  requiredPackages,
} from "../../config/app-constants";
import { ISingleTripQuoteData } from "../../config/interfaces";
import { ISingleTripQuoteContext, SingleTripQuoteContext } from "../TripQuote";
import ClearBorderCircle from "../icons/ClearBorderCircle";
import ForwardArrow from "../icons/ForwardArrow";
import PackageDisplayName from "./PackageDisplayName";
import PaymentIcons from "./PaymentIcons";
import TravelInsurance from "./TravelInsurance";

interface QuoteSummaryProps {
  pay: boolean;
}

const QuoteSummary: FC<QuoteSummaryProps> = (props: QuoteSummaryProps) => {
  const { pay } = props;
  const {
    quoteData,
    setQuoteData,
    quoteResult,
    next,
    tripType,
    packages,
    defaultPackages,
  } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;
  const removePackage = (packageAlias: string) => {
    const _quoteData: ISingleTripQuoteData = { ...quoteData };
    if (
      _quoteData.packages[packageAlias]?.defaultLevel?.id &&
      _quoteData.packages[packageAlias]?.level?.id !==
        _quoteData.packages[packageAlias]?.defaultLevel?.id
    ) {
      const pkg = packages.find((_pkg: any) => _pkg.alias === packageAlias);
      _quoteData.packages[packageAlias] = {
        package: pkg,
        level: pkg?.levels[0],
        defaultLevel: pkg?.levels[0],
      };
    } else {
      delete _quoteData.packages[packageAlias];
    }
    setQuoteData(_quoteData);
  };

  const benefitsAdded: any = quoteResult?.details
    ?.filter(
      (pkg: any) => pkg.type !== "level" && pkg.package_alias !== "base_package"
    )
    ?.filter((pkg: any) => {
      const pkgDetails: any = quoteData.packages[pkg.package_alias];
      const isRequired =
        pkgDetails?.package?.validations?.find((elem: any) => {
          return elem?.product_package_validation_type?.name === "Is Required";
        }) || requiredPackages.includes(pkg.alias);
      if (!isRequired) {
        return true;
      } else {
        if (pkgDetails?.level?.id === pkgDetails?.defaultLevel?.id) {
          return false;
        }
        return true;
      }
    })
    .filter(
      (result: any) =>
        result.amount - (defaultPackages[result.package_alias]?.amount || 0)
    );
  const totalInsured =
    quoteData.other_applicants?.filter((dob: Date | null) => dob != null)
      ?.length + 1;

  return (
    <div className={`${appStyles.quoteTotalBoxOuter}`}>
      <div className={`${appStyles.borderBox} ${appStyles.quoteTotalBox}`}>
        <div
          className={`${appStyles.textCenter} ${appStyles.quoteSummaryWrapper}`}
        >
          <h4>Get your Trip Insured for</h4>
          <h1 className={appStyles.colorPrimary}>
            {formatAmount(
              quoteResult?.quoted_price || 0,
              quoteData.host_country?.code || "US",
              quoteData.currency?.value || "GBP"
            )}

            <small className={appStyles.priceText}>(inc. tax)</small>
          </h1>

          <div className={appStyles.paymentButtonWrapper}>
            <div
              className={`${appStyles.buttonBlock} ${appStyles.buttonBlockAlignCenter}`}
            >
              <button
                className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconAfter}`}
                type={pay ? "submit" : "button"}
                onClick={() => {
                  !pay && next();
                }}
              >
                {pay ? "Buy Now" : "Checkout"}
                {` ${formatAmount(
                  quoteResult?.quoted_price || 0,
                  quoteData.host_country?.code || "US",
                  quoteData.currency?.value || "GBP"
                )}`}
                <span
                  className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}
                >
                  <ForwardArrow />
                </span>
              </button>
            </div>
            {pay && <PaymentIcons />}
            <button
              className={`${appStyles.bfLinkButton} ${appStyles.mXAuto}  ${appStyles.mY10}`}
              type='button'
              onClick={() =>
                window.open(
                  tripType === appConstants.ST
                    ? privacyPolicyDocs.singleTrip
                    : privacyPolicyDocs.multiTrip,
                  "_blank"
                )
              }
            >
              Download Policy Document
            </button>
            <button
              className={`${appStyles.bfLinkButton} ${appStyles.mXAuto}  ${appStyles.mY10}`}
              type='button'
              onClick={() =>
                window.open(
                  tripType === appConstants.ST
                    ? privacyPolicyDocs.singleTripIPID
                    : privacyPolicyDocs.multiTripIPID,
                  "_blank"
                )
              }
            >
              {tripType === appConstants.ST
                ? "Single Trip IPID"
                : "Annual Multi-Trip IPID"}
            </button>
          </div>
        </div>

        {benefitsAdded?.length > 0 ? (
          <div
            id='addedBenefits'
            className={`${appStyles.quoteSelectedBenifitsWrapper}`}
          >
            <p>
              <b>Your selected optional benefits (exc. tax):</b>
            </p>
            {benefitsAdded.map((result: any, index: number) => {
              return (
                <div
                  className={appStyles.quoteSelectedBenifitBlock}
                  key={index}
                >
                  <div className={appStyles.quoteSelectedBenifit}>
                    <b>
                      <PackageDisplayName packageName={result.type} />
                    </b>
                    <div>
                      {` ${formatAmount(
                        result.amount -
                          (defaultPackages[result.package_alias]?.amount || 0),
                        quoteData.host_country?.code || "US",
                        quoteData.currency?.value || "GBP"
                      )}`}
                    </div>
                  </div>
                  <div className={appStyles.btnDeleteWrap}>
                    {!requiredPackages.includes(result.package_alias) &&
                    !pay ? (
                      <a
                        className={appStyles.btnDelete}
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          removePackage(result.package_alias);
                        }}
                      >
                        <ClearBorderCircle width={20} height={20} />
                      </a>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      <TravelInsurance />
    </div>
  );
};

export default QuoteSummary;
