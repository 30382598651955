import { FC } from "react";

interface ClearCircleProps {
  width?: any;
  height?: any;
}

const ClearBorderCircle: FC<ClearCircleProps> = (props: ClearCircleProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width || 20}
      height={props.height || 20}
      viewBox='0 0 20 20'
      fill='none'
    >
      <circle cx='10' cy='10' r='9.5' fill='#F5F5F5' stroke='currentColor' />
      <path
        d='M7.2 13.5L6.5 12.8L9.3 10L6.5 7.2L7.2 6.5L10 9.3L12.8 6.5L13.5 7.2L10.7 10L13.5 12.8L12.8 13.5L10 10.7L7.2 13.5Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ClearBorderCircle;
