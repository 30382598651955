import { FC, useContext, useState } from "react";
import { ISingleTripQuoteContext, SingleTripQuoteContext } from "../TripQuote";
import appStyles from "../../App.module.css";
import { SelectOption } from "../../config/interfaces";
import appConstants, {
  calculateAge,
  formatDate,
} from "../../config/app-constants";

interface TripSummaryProps {}

const TripSummary: FC<TripSummaryProps> = (props: TripSummaryProps) => {
  const [showTripSummary, setShowTripSummary] = useState<boolean>(true);
  const { quoteData, tripType } = useContext(
    SingleTripQuoteContext
  ) as ISingleTripQuoteContext;
  return (
    <div
      className={`${appStyles.tripSummary} ${
        showTripSummary ? appStyles.tripSummaryVisible : ""
      }`}
    >
      <div
        className={`${appStyles.tripSummaryTitle} ${appStyles.tripSummaryAlwaysOpen}`}
        onClick={(e) => {
          setShowTripSummary(true);
        }}
      >
        <h4>Trip Summary</h4>
      </div>
      <div className={`${appStyles.tripSummaryContent}`}>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>Destination(s)</div>
          <div className={appStyles.tripSummaryValue}>
            {quoteData?.destinations
              ?.map((dest: SelectOption) => {
                return tripType === appConstants.AMT
                  ? dest?.description
                  : dest?.name;
              })
              .join(", ")}
          </div>
        </div>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>
            {appConstants.AMT === tripType
              ? "Cover Duration"
              : "Travel duration"}
          </div>
          <div className={appStyles.tripSummaryValue}>
            {`${formatDate(quoteData.start_date)} to
            ${formatDate(quoteData.end_date)}`}
          </div>
        </div>
        {/* maximum Trip Duration for Amt */}
        {tripType === appConstants.AMT && (
          <div className={`${appStyles.tripSummaryRow}`}>
            <div className={appStyles.tripSummaryLabel}>
              {quoteData.packages["base_package"].package.name}
            </div>
            <div className={appStyles.tripSummaryValue}>
              {quoteData.packages["base_package"].level?.level}
            </div>
          </div>
        )}

        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>No. of Insured</div>
          <div className={appStyles.tripSummaryValue}>
            {(quoteData?.other_applicants?.length || 0) + 1}
          </div>
        </div>
        <div className={`${appStyles.tripSummaryRow}`}>
          <div className={appStyles.tripSummaryLabel}>Age(s)</div>
          <div className={appStyles.tripSummaryValue}>
            {[quoteData.main_applicant]
              .concat(
                quoteData.other_applicants?.filter(
                  (dob: Date | null) => dob != null
                )
              )
              .map((dob: Date | null) => {
                return calculateAge(dob).years;
              })
              .join(", ")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripSummary;
