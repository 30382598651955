import { FC } from "react";
import appStyles from "../App.module.css";

interface TooltipIconProps {
  text: string;
  size?: "xs" | "sm";
  children?: JSX.Element;
}

const TooltipIcon: FC<TooltipIconProps> = (props: TooltipIconProps) => {
  const sizeClasses = {
    xs: appStyles.toolTipXs,
    sm: appStyles.toolTipSm,
  };
  const sizeClass: string = props.size
    ? sizeClasses[props.size]
    : sizeClasses.xs;
  return (
    <div className={appStyles.bfpToolTip}>
      <span className={appStyles.labelInfoIcon}></span>
      {props.text ? (
        <span
          className={`${appStyles.bfpToolTipText} ${sizeClass}`}
          dangerouslySetInnerHTML={{ __html: props.text }}
        ></span>
      ) : (
        <span className={`${appStyles.bfpToolTipText} ${sizeClass}`}>
          {props.children}
        </span>
      )}
    </div>
  );
};

export default TooltipIcon;
