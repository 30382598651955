import { FormikErrors } from "formik";
import React, { useEffect } from "react";

type Props = {
    submitCount: number;
    isValid: boolean;
    errors: FormikErrors<any>;
};
const ScrollToFirstError: React.FC<Props> = (props: Props) => {
    const { submitCount, isValid, errors } = props;

    useEffect(() => {
        if (isValid) {
            return;
        }

        // Find the field names with an error
        const fieldErrorNames = getFieldErrorNames(errors);
        if (fieldErrorNames.length <= 0) {
            return;
        }

        // Find the first element with an error
        // const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);
        const element = document.getElementById(fieldErrorNames[0]);
        if (!element) {
            return;
        }

        // Scroll to first known error into view
        element.scrollIntoView({ behavior: "smooth", block: "center" });
    }, [submitCount]);

    return null;
};

export default ScrollToFirstError;

const getFieldErrorNames = (formikErrors: any) => {
    const transformObjectToDotNotation = (obj: any, prefix = "", result: string[] = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (!value) {
                return;
            }

            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (typeof value === "object") {
                transformObjectToDotNotation(value, nextKey, result);
            } else {
                result.push(nextKey);
            }
        });

        return result;
    };

    return transformObjectToDotNotation(formikErrors);
};
