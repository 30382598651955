import { Formik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import * as yup from "yup";

import appStyles from "../../App.module.css";
import appConstants, {
  AdditionalPackages,
  formatAmount,
  packagesToHide,
  validateAge,
} from "../../config/app-constants";
import { ISingleTripQuoteData } from "../../config/interfaces";
import Stepper from "../Stepper";
import { ISingleTripQuoteContext, SingleTripQuoteContext } from "../TripQuote";
import Footer from "../common/Footer";
import HeaderImage from "../common/HeaderImage";
import QuoteSummary from "../common/QuoteSummary";
import TripSummary from "../common/TripSummary";
import ExcessFee from "../form-elements/ExcessFee";
import PackageSelector from "../form-elements/PackageSelector";
import BackArrow from "../icons/BackArrow";
import ForwardArrow from "../icons/ForwardArrow";

interface QuoteDetailProps {}

export interface IQuoteDetailForm {
  excess_fee: number;
  packages: any;
}

const validationSchema = yup.object().shape({
  excess_fee: yup.number().required(),
  packages: yup.object().required(),
});

const QuoteDetail: FC<QuoteDetailProps> = (props: QuoteDetailProps) => {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [hasError, setHasError] = useState<string | null>(null);

  const {
    quoteData,
    prev,
    next,
    quoteResult,
    setQuoteResult,
    packages,
    loader,
    setQuoteData,
    getQuote,
    setLoader,
    tripType,
    setDefaultPackages,
  } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;
  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }
    updateQuote();
  }, [quoteData]);

  const updateQuote = async () => {
    try {
      setHasError(null);
      if (!Object.keys(quoteData.packages || {}).length) {
        return;
      }
      setLoader("quote");
      let _quoteData: ISingleTripQuoteData = { ...quoteData };
      const _quoteResult = await getQuote(_quoteData);
      if (_quoteData.updateDefaults) {
        const _defaultQuoteResult = await getQuote(_quoteData, true);

        // setting default packages to cover base package scenario
        const _defaultPackage: any = {};
        _defaultQuoteResult.details.map((packageItem: any) => {
          const { package_alias, package_id, amount, type } = packageItem;
          _defaultPackage[package_alias] = { package_id, amount, type };
        });
        setDefaultPackages(_defaultPackage);
      }
      setQuoteResult(_quoteResult);
    } catch (e: any) {
      console.log(e);
      setHasError(e.message);
    } finally {
      setLoader(null);
    }
  };

  const handleFormSubmit = (values: IQuoteDetailForm) => {};

  const getBenefitsCount = () => {
    const hasExcessFees = quoteData.excess_fee ? 1 : 0;
    const count =
      hasExcessFees +
      Object.keys(quoteData.packages).length +
      Object.values(AdditionalPackages).length -
      1;
    return count;
  };

  return (
    <>
      {loader === "quote" && (
        <div className={appStyles.bfSpinnerOverlay}>
          <div className={appStyles.bfSpinner}></div>
        </div>
      )}

      <Formik
        initialValues={{
          excess_fee: quoteData.excess_fee,
          packages: quoteData.packages,
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={`${appStyles.mainContainer}`}>
              <div className={`${appStyles.quoteResultWrapper}`}>
                <div className={`${appStyles.quoteDetailsBox}`}>
                  <div className={appStyles.headerWrapper}>
                    <div className={appStyles.pageTitle}>
                      <h1 className={`${appStyles.colorPrimary}`}>
                        Your Quick Quote
                      </h1>
                    </div>
                    <HeaderImage />
                  </div>
                  <Stepper />

                  <TripSummary />

                  <div className={appStyles.pageSubTitle}>
                    <h2 className={appStyles.colorPrimary}>
                      Personalise your quote
                    </h2>
                    <p>
                      You can personalise the quote by adding/removing packages
                      as per your needs:
                    </p>
                    <div className={appStyles.formLabelHelper}>
                      <i>
                        *Benefits offered are per insured. Terms, Conditions,
                        Exclusions, Limitations and Excesses apply. Please read
                        the full policy wording to check it is suitable for your
                        needs.
                      </i>
                    </div>
                    {tripType === appConstants.AMT && (
                      <>
                        <br />
                        <div className={`${appStyles.formLabelHelper}`}>
                          <i>
                            Important: For Annual multi-trip policies you will
                            not be covered for holiday cancellation until your
                            policy start date. Please choose today's date if you
                            want cover as soon as possible.
                          </i>
                        </div>
                      </>
                    )}
                  </div>
                  <div className={appStyles.collapseWrapper}>
                    {/** Show excess fee only for single trip */}
                    {tripType === appConstants.ST && <ExcessFee />}
                    {/* {packages?.map((pkg: any, index: number) => {
                      if (!packagesToHide.includes(pkg.alias)) {
                        return <PackageSelector key={index} pkg={pkg} />;
                      }
                      return null;
                    })} */}

                    {packages?.map((pkg: any, index: number) => {
                      if (
                        (tripType === appConstants.ST &&
                          packagesToHide.includes(pkg.alias)) ||
                        (tripType === appConstants.AMT &&
                          packagesToHide.includes(pkg.alias) &&
                          validateAge(
                            quoteData.main_applicant,
                            quoteData.other_applicants,
                            quoteData.start_date
                          ) !== null)
                      ) {
                        return null;
                      }

                      return <PackageSelector key={index} pkg={pkg} />;
                    })}
                  </div>

                  {/* TODO: for now, api is not ready so commented */}
                  {/* <EmailQuote /> */}

                  <div className={appStyles.formRow}>
                    <div
                      className={`${appStyles.buttonBlock} ${appStyles.buttonBlockNextPrev}`}
                    >
                      <button
                        className={`${appStyles.bfButton} ${appStyles.bfButtonSecondary} ${appStyles.bfButtonOutline} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconBefore}`}
                        type='button'
                        onClick={() => {
                          // clearing packages from quoteData because we are getting packages on the basis of travellers age
                          const _clearedQuoteDataPackages = {
                            ...quoteData,
                            packages: null,
                          };
                          setQuoteData(_clearedQuoteDataPackages);
                          prev();
                        }}
                      >
                        Back
                        <span
                          className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconPrev}`}
                        >
                          <BackArrow />
                        </span>
                      </button>

                      <button
                        className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconAfter}`}
                        type='button'
                        onClick={() => {
                          next();
                        }}
                      >
                        Checkout
                        {` ${formatAmount(
                          quoteResult?.quoted_price || 0,
                          quoteData.host_country?.code || "US",
                          quoteData.currency?.value || "GBP"
                        )}`}
                        <span
                          className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}
                        >
                          <ForwardArrow />
                        </span>
                      </button>
                    </div>
                  </div>

                  {hasError && (
                    <div className={appStyles.formRow}>
                      <div className={appStyles.battlefaceServerError}>
                        {appConstants.defaultAPIError}
                      </div>
                    </div>
                  )}
                  <Footer />

                  <div className={appStyles.bfMobileFooter}>
                    <div className={appStyles.bfMobileFooterLeft}>
                      <h1 className={appStyles.colorPrimary}>
                        {formatAmount(
                          quoteResult?.quoted_price || 0,
                          quoteData.host_country?.code || "US",
                          quoteData.currency?.value || "GBP"
                        )}
                      </h1>
                      <a
                        className={appStyles.bfbadge}
                        href='#addedBenefits'
                        onClick={(e) => {
                          e.preventDefault();
                          document
                            .getElementById("addedBenefits")
                            ?.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                        }}
                      >
                        {getBenefitsCount()} benefits added
                      </a>
                    </div>
                    <div className={appStyles.bfMobileFooterRight}>
                      <button
                        className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconAfter}`}
                        type='button'
                        onClick={() => {
                          next();
                        }}
                      >
                        Checkout
                        {` ${formatAmount(
                          quoteResult?.quoted_price || 0,
                          quoteData.host_country?.code || "US",
                          quoteData.currency?.value || "GBP"
                        )}`}
                        <span
                          className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}
                        >
                          <ForwardArrow />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <QuoteSummary pay={false} />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default QuoteDetail;
