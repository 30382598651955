import { ErrorMessage, Formik, getIn } from "formik";
import { FC, useContext, useMemo, useState } from "react";
import DatePicker from "react-date-picker";
import Select from "react-select";
import * as yup from "yup";

import appStyles from "../../App.module.css";
import QuoteApi from "../../api/quote";
import appConstants, {
  AdditionalPackages,
  calculateAge,
  convertDateToApiFormat,
  formatAmount,
  privacyPolicyDocs,
  selectControlStyling,
  selectOptionControlStyling,
  subtractYears,
  isValidDate,
} from "../../config/app-constants";
import {
  IOrderForm,
  ISingleTripQuoteData,
  SelectOption,
} from "../../config/interfaces";
import Stepper from "../Stepper";
import TooltipIcon from "../TooltipIcon";
import { ISingleTripQuoteContext, SingleTripQuoteContext } from "../TripQuote";
import Footer from "../common/Footer";
import HeaderImage from "../common/HeaderImage";
import PaymentIcons from "../common/PaymentIcons";
import QuoteSummary from "../common/QuoteSummary";
import ScrollToFirstError from "../common/ScrollToFirstError";
import TripSummary from "../common/TripSummary";
import BackArrow from "../icons/BackArrow";
import Clear from "../icons/Clear";
import ForwardArrow from "../icons/ForwardArrow";

interface OrderFormProps {}

const validationSchema = {
  applicants: yup.array().of(
    yup.object().shape({
      first_name: yup.string().required(),
      middle_name: yup.string(),
      last_name: yup.string().required(),
      dob: yup.date().required(),
      is_main: yup.boolean(),
      email: yup.string().when("is_main", {
        is: true,
        then() {
          return yup
            .string()
            .email()
            .matches(appConstants.EMAIL_REGEX, "Invalid email address")
            .required();
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),

      contact_number: yup.string().when("is_main", {
        is: true,
        then(schema) {
          return schema.required();
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),

      address: yup.string().when("is_main", {
        is: true,
        then(schema) {
          return schema.required();
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),

      city: yup.string().when("is_main", {
        is: true,
        then(schema) {
          return schema.required();
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),

      post_code: yup.string().when("is_main", {
        is: true,
        then(schema) {
          return schema.required();
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),
    })
  ),
  accept_agreement: yup
    .boolean()
    .required()
    .oneOf([true], "You must confirm the above statement."),
  accept_disclosure: yup
    .boolean()
    .required()
    .oneOf([true], "You must accept the terms and conditions."),
  registerForBattlefaceAccount: yup.boolean(),
  newsletterSubscription: yup.boolean(),
};

const initApplicant = {
  first_name: "",
  middle_name: "",
  last_name: "",
  dob: "",
  age_at_travel: "",
  email: "",
  contact_number: "",
  address: "",
  city: "",
  post_code: "",
  country_code: "GB",
  is_main: false,
  trip_cost: 1,
};

const OrderForm: FC<OrderFormProps> = (props: OrderFormProps) => {
  const {
    quoteData,
    setQuoteData,
    packages,
    prev,
    quoteResult,
    setOrder,
    next,
    hostCountries,
    loader,
    tripType,
  } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<SelectOption | null>(
    quoteData.host_country
  );

  const allApplicants = useMemo(() => {
    const _allApplicants: any[] = [];
    if (quoteData?.all_applicants?.length) {
      _allApplicants.push({
        ...quoteData.all_applicants[0],
        ...{
          country_code: quoteData.host_country?.code,
          dob: quoteData.main_applicant,
          age_at_travel: calculateAge(quoteData.main_applicant).years,
          is_main: true,
        },
      });
    } else {
      _allApplicants.push({
        ...initApplicant,
        country_code: quoteData.host_country?.code,
        dob: quoteData.main_applicant,
        age_at_travel: calculateAge(quoteData.main_applicant).years,
        is_main: true,
      });
    }

    quoteData.other_applicants
      ?.filter((obj: any) => {
        return obj !== null;
      })
      .forEach((dob: any, index: number) => {
        if (quoteData.all_applicants && quoteData.all_applicants[index + 1]) {
          _allApplicants.push({
            ...quoteData.all_applicants[index + 1],
            ...{
              country_code: quoteData.host_country?.code,
              dob: dob,
              age_at_travel: calculateAge(dob).years,
              is_main: false,
            },
          });
        } else {
          _allApplicants.push({
            ...initApplicant,
            country_code: quoteData.host_country?.code,
            dob: dob,
            age_at_travel: calculateAge(dob).years,
            is_main: false,
          });
        }
      });
    return _allApplicants;
  }, [quoteData.main_applicant, quoteData.other_applicants.length]);

  const isGuardianRequired: boolean = useMemo(
    () =>
      allApplicants.every((item: any) => {
        return item.age_at_travel < 18;
      }),
    [allApplicants]
  );

  const _updatedValidationSchema = !isGuardianRequired
    ? yup.object().shape(validationSchema)
    : yup.object().shape({
        ...validationSchema,
        guardian: yup.object().shape({
          guardian_first_name: yup.string().trim().required(),
          guardian_last_name: yup.string().trim().required(),
          guardian_middle_name: yup.string().trim().notRequired(),
          guardian_dob: yup
            .date()
            .required("This field is required")
            .test("guardian_dob", "Invalid date entered.", (dob: any) => {
              const age = calculateAge(dob, new Date());
              return isValidDate(dob) && age.years <= 150;
            })
            .test(
              "guardian_dob",
              "Guardian age should be more than 18 years",
              (dob) => {
                const age = calculateAge(dob, new Date());
                return age.years >= 18;
              }
            ),
        }),
      });

  const initialFormValues: IOrderForm = {
    applicants: allApplicants,
    accept_agreement: false,
    accept_disclosure: false,
    registerForBattlefaceAccount: false,
    newsletterSubscription: false,
    guardian: {
      guardian_first_name: "",
      guardian_last_name: "",
      guardian_middle_name: "",
      guardian_email: "",
      guardian_contact_number: "",
      guardian_postal_address: "",
      guardian_dob: null,
    },
  };

  const getApplicantErrorClass = (
    errors: any,
    touched: any,
    index: any,
    name: string
  ) => {
    if (
      errors?.applicants &&
      errors?.applicants[index] &&
      errors?.applicants[index][name] &&
      touched?.applicants &&
      touched?.applicants[index] &&
      touched?.applicants[index][name]
    ) {
      return appStyles.hasError;
    }
    return "";
  };

  const getGuardianErrorClass = (errors: any, touched: any, name: string) => {
    if (
      errors?.guardian &&
      errors?.guardian[name] &&
      touched?.guardian &&
      touched?.guardian[name]
    ) {
      return appStyles.hasError;
    }
    return "";
  };

  const handleFormSubmit = async (values: IOrderForm) => {
    try {
      setLoading(true);
      setHasError(null);

      const _quoteData: ISingleTripQuoteData = { ...quoteData };
      _quoteData.all_applicants = values.applicants;
      setQuoteData(_quoteData);

      // const primaryApplicant: any = values?.applicants?.filter((obj) => {
      //   return obj.is_main;
      // });
      const details: any[] = [];
      if (quoteResult?.deductibles?.excess_fee) {
        details.push({
          type: "deductible",
          detail: "excess_fee",
          amount: quoteResult?.deductibles?.excess_fee,
        });
      }

      quoteResult?.details.forEach((detail: any) => {
        if (detail?.type !== "level") {
          details.push({
            type: "package",
            product_package_id: detail.package_alias,
          });
        } else {
          if (detail.detail) {
            const value: any =
              packages
                ?.find((pkg: any) => pkg.alias === detail.package_alias)
                ?.levels?.find((level: any) => level.id == detail.value)
                ?.value || "";
            details.push({
              type: "level-condition",
              detail: "level",
              value: `${value}`,
              product_package_id: detail.package_alias,
            });
          }
        }
      });

      const destinations = quoteData.destinations?.map((dest: SelectOption) => {
        return tripType === appConstants.ST
          ? { country_code: dest?.code }
          : { destination_group: dest?.name };
      });

      const data: any = {
        quote_id: quoteResult?.id,
        currency_id: quoteData.currency?.value,
        sanction_search_id: 0,
        product_id:
          tripType === appConstants.ST
            ? appConstants.singleTripProductId
            : appConstants.multiTripProductId,
        start_at: convertDateToApiFormat(quoteData.start_date),
        end_at: convertDateToApiFormat(quoteData.end_date),
        signed_at: convertDateToApiFormat(new Date()),
        deposit_at: convertDateToApiFormat(new Date()),
        details: details,
        applicants: values?.applicants?.map((obj: any) => {
          let object: any = {};
          Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === "string") {
              if (obj[key].trim() !== "") {
                object[key] = obj[key];
              }
            } else {
              object[key] = obj[key];
            }
          });
          const applicant = { ...object };
          applicant.dob =
            applicant.dob && convertDateToApiFormat(applicant?.dob);

          return applicant;
        }),
        ...(isGuardianRequired && {
          guardian: {
            first_name: values.guardian.guardian_first_name,
            last_name: values.guardian.guardian_last_name,
            middle_name: values.guardian.guardian_middle_name,
            dob: convertDateToApiFormat(values.guardian.guardian_dob),
          },
        }),
        destinations,
        affiliate: {
          id: quoteData.affiliate_id,
        },
      };

      if (quoteData?.external_reference_id?.length > 0) {
        data.external_reference_id = quoteData.external_reference_id;
      }

      // if middle is empty then removing the middle name field
      if (
        Object.keys(values.guardian).length > 0 &&
        values.guardian.guardian_middle_name.length === 0
      ) {
        delete data.guardian?.middle_name;
      }

      const response: any = await QuoteApi.createOrder(data);

      if (response.error) {
        throw response;
      }

      setOrder(response.data);
      next();
    } catch (e: any) {
      console.log(e);
      setHasError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getBenefitsCount = () => {
    const hasExcessFees = quoteData.excess_fee ? 1 : 0;
    const count =
      hasExcessFees +
      Object.keys(quoteData.packages).length +
      Object.values(AdditionalPackages).length;
    return count;
  };

  return (
    <>
      {loading && (
        <div className={appStyles.bfSpinnerOverlay}>
          <div className={appStyles.bfSpinner}></div>
        </div>
      )}
      <Formik
        initialValues={initialFormValues}
        validationSchema={_updatedValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          handleSubmit,
          handleChange,
          isValid,
          submitCount,
          validateForm,
        }) => (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await validateForm();
              handleSubmit();
            }}
          >
            <ScrollToFirstError
              submitCount={submitCount}
              isValid={isValid}
              errors={errors}
            />
            <div className={`${appStyles.mainContainer}`}>
              <div className={`${appStyles.quoteResultWrapper}`}>
                <div className={`${appStyles.quoteDetailsBox}`}>
                  <div className={appStyles.headerWrapper}>
                    <div className={appStyles.pageTitle}>
                      <h1 className={`${appStyles.colorPrimary}`}>
                        Policy Information
                      </h1>
                    </div>
                    <HeaderImage />
                  </div>
                  <Stepper />
                  <TripSummary />

                  <div className={appStyles.formRow}>
                    <div className={appStyles.warningText}>
                      <span className={appStyles.infoIcon}></span>
                      <p>
                        NOTE: As a consumer you have a duty to take reasonable
                        care to answer questions fully and accurately. This duty
                        exists when applying for cover and throughout the entire
                        duration of the insurance. If you do not do so, you
                        insurer may be able to void the policy from inception.
                      </p>
                    </div>
                  </div>

                  {values.applicants.map((applicant: any, index: any) => {
                    return (
                      <div key={index}>
                        {!index ? (
                          <div className={appStyles.pageSubTitle}>
                            <h2 className={appStyles.colorPrimary}>
                              Primary Traveller Information (You)
                            </h2>
                          </div>
                        ) : (
                          <div className={appStyles.pageSubTitle}>
                            <h2 className={appStyles.colorPrimary}>
                              Other Traveller(s) Information (Traveller{" "}
                              {index + 1})
                            </h2>
                          </div>
                        )}
                        <div
                          className={`${appStyles.quoteFormBlock} ${appStyles.quoteFormPersonalInformation}`}
                        >
                          <div className={appStyles.quoteFormBlockTitle}>
                            <h3 className={appStyles.colorPrimary}>
                              Personal Information
                            </h3>
                          </div>

                          {index > 0 && (
                            <div className={appStyles.formRow}>
                              <div className={appStyles.formField}>
                                Date of birth:
                              </div>
                              <div
                                className={`${appStyles.formField} ${appStyles.fornFieldHasCloseButton}`}
                              >
                                <DatePicker
                                  calendarClassName={
                                    appStyles.bfpDatePickerCalendar
                                  }
                                  className={appStyles.bfpDatePicker}
                                  format={appConstants.datePickerFormat}
                                  value={values.applicants[index].dob}
                                  dayPlaceholder='dd'
                                  monthPlaceholder='mm'
                                  yearPlaceholder='yyyy'
                                  onChange={(value: any) => {}}
                                  calendarIcon={() => null}
                                  clearIcon={() => null}
                                  maxDate={subtractYears(new Date(), 1)}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          )}

                          <div className={appStyles.formRow}>
                            <label>Your full name:</label>
                          </div>
                          <div className={appStyles.formRow}>
                            <div className={appStyles.formBlock}>
                              <div
                                className={`${
                                  appStyles.formField
                                } ${getApplicantErrorClass(
                                  errors,
                                  touched,
                                  index,
                                  "first_name"
                                )}`}
                              >
                                <input
                                  type='text'
                                  name={`applicants.${index}.first_name`}
                                  id={`applicants.${index}.first_name`}
                                  value={values.applicants[index]?.first_name}
                                  className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                  placeholder='First Name*'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  name={`applicants.${index}.first_name`}
                                >
                                  {(msg) => (
                                    <div className={appStyles.formError}>
                                      This field is required
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>
                            </div>
                            <div className={appStyles.formBlock}>
                              <div
                                className={`${
                                  appStyles.formField
                                } ${getApplicantErrorClass(
                                  errors,
                                  touched,
                                  index,
                                  "middle_name"
                                )}`}
                              >
                                <input
                                  type='text'
                                  name={`applicants.${index}.middle_name`}
                                  id={`applicants.${index}.middle_name`}
                                  value={values.applicants[index]?.middle_name}
                                  className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                  placeholder='Middle Name'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  name={`applicants.${index}.middle_name`}
                                >
                                  {(msg) => (
                                    <div className={appStyles.formError}>
                                      This field is required
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>
                            </div>
                            <div className={appStyles.formBlock}>
                              <div
                                className={`${
                                  appStyles.formField
                                } ${getApplicantErrorClass(
                                  errors,
                                  touched,
                                  index,
                                  "last_name"
                                )}`}
                              >
                                <input
                                  type='text'
                                  name={`applicants.${index}.last_name`}
                                  id={`applicants.${index}.last_name`}
                                  value={values.applicants[index]?.last_name}
                                  className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                  placeholder='Last Name*'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  name={`applicants.${index}.last_name`}
                                >
                                  {(msg) => (
                                    <div className={appStyles.formError}>
                                      This field is required
                                    </div>
                                  )}
                                </ErrorMessage>
                              </div>
                            </div>
                          </div>
                          {index === 0 && (
                            <>
                              <div className={appStyles.formRow}>
                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${
                                      appStyles.formField
                                    } ${getApplicantErrorClass(
                                      errors,
                                      touched,
                                      index,
                                      "email"
                                    )}`}
                                  >
                                    <label>Your email*</label>
                                    <input
                                      type='text'
                                      name={`applicants.${index}.email`}
                                      id={`applicants.${index}.email`}
                                      value={values.applicants[index]?.email}
                                      className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                      placeholder='Please enter your email'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      name={`applicants.${index}.email`}
                                    >
                                      {(msg) => (
                                        <div className={appStyles.formError}>
                                          This field is required
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${
                                      appStyles.formField
                                    } ${getApplicantErrorClass(
                                      errors,
                                      touched,
                                      index,
                                      "contact_number"
                                    )}`}
                                  >
                                    <label>Your Phone*</label>
                                    <input
                                      type='text'
                                      name={`applicants.${index}.contact_number`}
                                      id={`applicants.${index}.contact_number`}
                                      value={
                                        values.applicants[index]?.contact_number
                                      }
                                      className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                      placeholder='Please enter your contact number'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      name={`applicants.${index}.contact_number`}
                                    >
                                      {(msg) => (
                                        <div className={appStyles.formError}>
                                          This field is required
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>

                              <div className={appStyles.quoteFormBlockTitle}>
                                <h3 className={appStyles.colorPrimary}>
                                  Address
                                </h3>
                              </div>
                              <div className={appStyles.formRow}>
                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${
                                      appStyles.formField
                                    } ${getApplicantErrorClass(
                                      errors,
                                      touched,
                                      index,
                                      "address"
                                    )}`}
                                  >
                                    <label>Street Address*</label>
                                    <input
                                      type='text'
                                      name={`applicants.${index}.address`}
                                      id={`applicants.${index}.address`}
                                      value={values.applicants[index]?.address}
                                      className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                      placeholder='Please enter street address...'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      name={`applicants.${index}.address`}
                                    >
                                      {(msg) => (
                                        <div className={appStyles.formError}>
                                          This field is required
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                              <div className={appStyles.formRow}>
                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${
                                      appStyles.formField
                                    } ${getApplicantErrorClass(
                                      errors,
                                      touched,
                                      index,
                                      "city"
                                    )}`}
                                  >
                                    <label>City*</label>
                                    <input
                                      type='text'
                                      name={`applicants.${index}.city`}
                                      id={`applicants.${index}.city`}
                                      value={values.applicants[index]?.city}
                                      className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                      placeholder='City'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      name={`applicants.${index}.city`}
                                    >
                                      {(msg) => (
                                        <div className={appStyles.formError}>
                                          This field is required
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>
                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${
                                      appStyles.formField
                                    } ${getApplicantErrorClass(
                                      errors,
                                      touched,
                                      index,
                                      "post_code"
                                    )}`}
                                  >
                                    <label>Postal Code*</label>
                                    <input
                                      type='text'
                                      name={`applicants.${index}.post_code`}
                                      id={`applicants.${index}.post_code`}
                                      value={
                                        values.applicants[index]?.post_code
                                      }
                                      className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                      placeholder='Postal Code'
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      name={`applicants.${index}.post_code`}
                                    >
                                      {(msg) => (
                                        <div className={appStyles.formError}>
                                          This field is required
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>

                                <div className={appStyles.formBlock}>
                                  <div
                                    className={`${appStyles.formField}  ${
                                      getIn(
                                        touched,
                                        `applicants.${index}.country_code`
                                      ) &&
                                      getIn(
                                        errors,
                                        `applicants.${index}.country_code`
                                      )
                                        ? appStyles.hasError
                                        : ""
                                    }`}
                                  >
                                    <label>Country*</label>

                                    {hostCountries.length === 1 ? (
                                      <input
                                        type='text'
                                        name={`applicants.${index}.country_code`}
                                        id={`applicants.${index}.country_code`}
                                        value={
                                          quoteData.host_country
                                            ? quoteData.host_country.name
                                            : ""
                                        }
                                        className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                        disabled={true}
                                      />
                                    ) : (
                                      <Select
                                        isSearchable={true}
                                        name={`applicants.${index}.country_code`}
                                        id={`applicants.${index}.country_code`}
                                        required={true}
                                        options={hostCountries}
                                        isLoading={loader === "master_data"}
                                        classNames={{
                                          control: selectControlStyling,
                                          option: selectOptionControlStyling,
                                        }}
                                        onBlur={handleBlur}
                                        onChange={(newValue) => {
                                          setSelectedCountry(newValue);
                                          setFieldValue(
                                            "country_code",
                                            newValue?.code
                                          );
                                        }}
                                        value={selectedCountry}
                                        getOptionValue={(option: any) =>
                                          `${option["code"]}`
                                        }
                                        getOptionLabel={(option: any) =>
                                          `${option["name"]}`
                                        }
                                      />
                                    )}

                                    <ErrorMessage
                                      name={`applicants.${index}.country_code`}
                                    >
                                      {(msg) => (
                                        <div className={appStyles.formError}>
                                          This field is required
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  {isGuardianRequired && (
                    <>
                      <div className={appStyles.pageSubTitle}>
                        <h2 className={appStyles.colorPrimary}>
                          Guardian Details
                        </h2>
                      </div>
                      <div className={appStyles.formRow}>
                        <div
                          className={`${appStyles.warningText} ${appStyles.warningWraperSpacing}`}
                        >
                          <span className={appStyles.guardianInfoIcon}></span>
                          <p>
                            When purchasing this policy for someone under the
                            age of 18, list the email and phone number of the
                            person who should be contacted about this policy in
                            the primary traveller information section above.
                          </p>
                        </div>
                      </div>
                      <div
                        className={`${appStyles.quoteFormBlock} ${appStyles.quoteFormPersonalInformation}`}
                      >
                        <div className={appStyles.quoteFormBlockTitle}>
                          <h3 className={appStyles.colorPrimary}>
                            Personal Information
                          </h3>
                        </div>

                        <div className={appStyles.formRow}>
                          <div className={appStyles.formField}>
                            Date of birth:
                          </div>
                          <div
                            className={`${appStyles.formField} ${
                              touched?.guardian?.guardian_dob &&
                              errors?.guardian?.guardian_dob
                                ? appStyles.hasError
                                : ""
                            }`}
                          >
                            <DatePicker
                              calendarClassName={
                                appStyles.bfpDatePickerCalendar
                              }
                              className={appStyles.bfpDatePicker}
                              format={appConstants.datePickerFormat}
                              value={values.guardian?.guardian_dob}
                              dayPlaceholder='dd'
                              monthPlaceholder='mm'
                              yearPlaceholder='yyyy'
                              onChange={(value: any) => {
                                setFieldValue("guardian.guardian_dob", value);
                              }}
                              onKeyDown={(e: any) => {
                                const inputValue = e.target.value;
                                const year = inputValue.slice(-4);
                                if (year.length === 4 && e.key.length === 1) {
                                  e.preventDefault();
                                }
                              }}
                              id='guardian.guardian_dob'
                              maxDate={new Date()}
                              clearIcon={() => {
                                return <Clear />;
                              }}
                              disableCalendar={true}
                            />
                            {touched?.guardian?.guardian_dob &&
                              errors?.guardian?.guardian_dob && (
                                <div className={appStyles.formError}>
                                  {errors?.guardian?.guardian_dob}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className={appStyles.formRow}>
                          <label>Your full name:</label>
                        </div>
                        <div className={appStyles.formRow}>
                          <div className={appStyles.formBlock}>
                            <div
                              className={`${
                                appStyles.formField
                              } ${getGuardianErrorClass(
                                errors,
                                touched,
                                "guardian_first_name"
                              )}`}
                            >
                              <input
                                type='text'
                                name={"guardian.guardian_first_name"}
                                id={`guardian.guardian_first_name`}
                                value={values.guardian?.guardian_first_name}
                                className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                placeholder='First Name*'
                                onChange={handleChange(
                                  "guardian.guardian_first_name"
                                )}
                                onBlur={handleBlur(
                                  "guardian.guardian_first_name"
                                )}
                              />
                              <ErrorMessage
                                name={"guardian.guardian_first_name"}
                              >
                                {(msg) => (
                                  <div className={appStyles.formError}>
                                    This field is required
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>

                          <div className={appStyles.formBlock}>
                            <div
                              className={`${
                                appStyles.formField
                              } ${getApplicantErrorClass(
                                errors,
                                touched,
                                0,
                                "guardian_"
                              )}`}
                            >
                              <input
                                type='text'
                                name={`guardian.guardian_middle_name`}
                                id={`guardian.guardian_middle_name`}
                                value={values.guardian?.guardian_middle_name}
                                className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                placeholder='Middle Name'
                                onChange={handleChange(
                                  "guardian.guardian_middle_name"
                                )}
                                onBlur={handleBlur(
                                  "guardian.guardian_middle_name"
                                )}
                              />
                              <ErrorMessage
                                name={`guardian.guardian_middle_name`}
                              >
                                {(msg) => (
                                  <div className={appStyles.formError}>
                                    This field is required
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className={appStyles.formBlock}>
                            <div
                              className={`${
                                appStyles.formField
                              } ${getGuardianErrorClass(
                                errors,
                                touched,
                                "guardian_last_name"
                              )}`}
                            >
                              <input
                                type='text'
                                name={`guardian.guardian_last_name`}
                                id={`guardian.guardian_last_name`}
                                value={values.guardian?.guardian_last_name}
                                className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                                placeholder='Last Name*'
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                name={`guardian.guardian_last_name`}
                              >
                                {(msg) => (
                                  <div className={appStyles.formError}>
                                    This field is required
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className={appStyles.pageSubTitle}>
                    <h2 className={appStyles.colorPrimary}>Agreement(s)</h2>
                  </div>

                  <div
                    className={`${appStyles.quoteFormBlock} ${appStyles.quoteFormOtherTravelerInformation}`}
                  >
                    <div className={appStyles.formRow}>
                      <p className={appStyles.formLabelHelper}>
                        <br />
                        To purchase battleface Travel Medical Insurance, EACH
                        applicant MUST be able to confirm the following
                        statements:
                      </p>
                    </div>
                    <div className={appStyles.formRow}>
                      <ul className={appStyles.formLabelHelper}>
                        <li>
                          I have (or will have) received all immunisations
                          recommended by a qualified doctor in my Home Country
                          prior to entering the destination country
                        </li>
                        <li>
                          {tripType === appConstants.AMT
                            ? "I will be aged 75 years or under on the date I require cover to commence"
                            : "I will be over one month old on the date I require cover to commence and 79 years or under on the date I require cover to cease"}
                        </li>
                        <li>
                          I will not be an active member of any military or para
                          military force at any time during the Policy Period
                        </li>
                        <li>
                          {tripType === appConstants.AMT
                            ? "I understand cover is only valid whilst travelling outside my home country and within the selected territories as listed on Your Insurance Certificate"
                            : "I understand cover is only valid whilst travelling  outside my home country"}
                        </li>
                      </ul>
                    </div>
                    <div className={appStyles.formRow}>
                      <div className={appStyles.formBlock}>
                        <div
                          className={`${appStyles.formField} ${
                            errors.accept_agreement &&
                            touched.accept_agreement &&
                            appStyles.hasError
                          }`}
                        >
                          <div className={`${appStyles.bfCheckBox}`}>
                            <label
                              htmlFor='accept_agreement'
                              className={appStyles.formLabelHelper}
                            >
                              <input
                                type='checkbox'
                                id='accept_agreement'
                                name='accept_agreement'
                                value='yes'
                                checked={values.accept_agreement}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue(
                                    "accept_agreement",
                                    e.target.checked
                                  );
                                }}
                              />
                              <span
                                className={`${appStyles.bfCheckmark}`}
                              ></span>
                              I have read and confirmed the above statements.
                            </label>
                            <ErrorMessage name='accept_agreement'>
                              {(msg) => (
                                <div className={appStyles.formError}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={appStyles.formRow}>
                      <div className={appStyles.formBlock}>
                        <div
                          className={`${appStyles.formField} ${
                            errors.accept_disclosure &&
                            touched.accept_disclosure &&
                            appStyles.hasError
                          }`}
                        >
                          <div className={appStyles.bfCheckBox}>
                            <label
                              htmlFor='accept_disclosure'
                              className={appStyles.formLabelHelper}
                            >
                              <input
                                type='checkbox'
                                id='accept_disclosure'
                                name='accept_disclosure'
                                value='yes'
                                checked={values.accept_disclosure}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue(
                                    "accept_disclosure",
                                    e.target.checked
                                  );
                                }}
                              />
                              <span className={appStyles.bfCheckmark}></span>I
                              have read and agree to the&nbsp;
                              <a
                                href={`${privacyPolicyDocs.travelMedicalDisclosures}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={`${appStyles.textDecorationNone}`}
                              >
                                Insurance Disclosures
                              </a>
                              &nbsp;and&nbsp;
                              <a
                                href={`${
                                  privacyPolicyDocs.termsCondition
                                }?${new Date().getTime()}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={`${appStyles.textDecorationNone}`}
                              >
                                Terms and Conditions
                              </a>
                              .
                            </label>
                            <ErrorMessage name='accept_disclosure'>
                              {(msg) => (
                                <div className={appStyles.formError}>{msg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Automatic Renewal  */}
                  {/* <div className={appStyles.pageSubTitle}>
                    <h2 className={appStyles.colorPrimary}>
                      Automatic Renewal
                    </h2>
                  </div>

                  <div
                    className={`${appStyles.quoteFormBlock} ${appStyles.quoteFormOtherTravelerInformation}`}
                  >
                    <div className={appStyles.formRow}>
                      <p className={appStyles.formLabelHelper}>
                        <br />
                        To ensure you are always covered on your adventures, we
                        can automatically renew this policy when it is about to
                        expire.
                      </p>
                    </div>
                    <div className={appStyles.formRow}>
                      <div className={appStyles.formBlock}>
                        <div
                          className={`${appStyles.formField} ${
                            errors.accept_agreement &&
                            touched.accept_agreement &&
                            appStyles.hasError
                          }`}
                        >
                          <div className={`${appStyles.bfCheckBox}`}>
                            <label htmlFor='accept_agreement' className={appStyles.formLabelHelper}>
                              <input
                                type='checkbox'
                                id='accept_agreement'
                                name='accept_agreement'
                                value='yes'
                                checked={values.accept_agreement}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue(
                                    "accept_agreement",
                                    e.target.checked
                                  );
                                }}
                              />
                              <span
                                className={`${appStyles.bfCheckmark}`}
                              ></span>
                              I want continuous travel insurance cover and agree
                              to Opt into the auto renewal process
                            </label>
                            <ErrorMessage name='accept_agreement'>
                              {(msg) => (
                                <div className={appStyles.formError}>
                                  You must accept the terms and conditions
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
               */}
                  <div
                    className={`${appStyles.quoteFormBlock} ${appStyles.quoteFormOtherTravelerInformation} ${appStyles.bfMarginTop}`}
                  >
                    <div className={appStyles.formLabelHelper}>
                      <div>
                        Please note there is no cover for any claim arising
                        directly or indirectly from a known Pre-existing Medical
                        Condition*
                        <TooltipIcon size='sm' text={``}>
                          <>
                            *A Pre-existing Medical Condition is any Medical
                            Condition that, within the last 12 months, required
                            any:
                            <ol style={{ paddingLeft: 15 }}>
                              <li>
                                Surgery, inpatient or outpatient treatment,
                                referrals or investigations of any sort. This
                                includes being on any waiting list, taking any
                                prescription medication, tablets or required
                                medical treatment (This will not apply to common
                                colds, flu or contraceptive medication).
                              </li>
                              <li>
                                Medical advice or treatment for any respiratory
                                condition relating to the lungs or breathing.
                              </li>
                              <li>
                                Medical advice or treatment for any heart,
                                stroke or diabetic condition.
                              </li>
                            </ol>
                          </>
                        </TooltipIcon>
                      </div>
                      <p>
                        This exclusion cannot be removed from the policy. If you
                        or someone you are travelling with requires cover for a
                        condition which falls within the exclusion above, you
                        may be able to find alternative insurance from the Money
                        Advice Service travel directory here:&nbsp;
                        <a
                          href='https://traveldirectory.moneyadviceservice.org.uk/en/listings'
                          target='_blank'
                          rel='noopener noreferrer'
                          className={`${appStyles.breakWord}`}
                        >
                          https://traveldirectory.moneyadviceservice.org.uk/en/listings
                        </a>
                        &nbsp;or by calling 0800 138 7777.
                      </p>
                      <p>
                        All firms on the directory are authorised and regulated
                        by the FCA. Please be aware that benefits and limits
                        will vary and some providers may not offer cover in
                        destinations carrying a government travel or FCDO
                        warning.
                      </p>
                    </div>
                    {/* <div className={appStyles.formRow}>
											<div className={appStyles.formBlock}>
												<div
													className={`${appStyles.formField} ${
														errors.registerForBattlefaceAccount &&
														touched.registerForBattlefaceAccount &&
														appStyles.hasError
													}`}
												>
													<div className={`${appStyles.bfCheckBox}`}>
														<label htmlFor='registerForBattlefaceAccount' className={appStyles.formLabelHelper}>
															<input
																type='checkbox'
																id='registerForBattlefaceAccount'
																name='registerForBattlefaceAccount'
																value='yes'
																checked={values.registerForBattlefaceAccount}
																onBlur={handleBlur}
																onChange={(e) => {
																	setFieldValue("registerForBattlefaceAccount", e.target.checked);
																}}
															/>
															<span className={`${appStyles.bfCheckmark}`}></span>I would like to register for a
															battleface account
														</label>
														<ErrorMessage name='registerForBattlefaceAccount'>
															{(msg) => (
																<div className={appStyles.formError}>You must accept the terms and conditions</div>
															)}
														</ErrorMessage>
													</div>
												</div>
											</div>
										</div> 
										<div className={appStyles.formRow}>
											<div className={appStyles.formBlock}>
												<div
													className={`${appStyles.formField} ${
														errors.newsletterSubscription && touched.newsletterSubscription && appStyles.hasError
													}`}
												>
													<div className={appStyles.bfCheckBox}>
														<label htmlFor='newsletterSubscription' className={appStyles.formLabelHelper}>
															<input
																type='checkbox'
																id='newsletterSubscription'
																name='newsletterSubscription'
																value='yes'
																checked={values.newsletterSubscription}
																onBlur={handleBlur}
																onChange={(e) => {
																	setFieldValue("newsletterSubscription", e.target.checked);
																}}
															/>
															<span className={appStyles.bfCheckmark}></span>
															Stay in the Loop! Tick here to join the Eurochange and battleface community, You'll
															receive travel inspiration, tips, insights and news straight to your inbox. We'll keep
															your data safe and you can opt-out anytime{" "}
														</label>
														<ErrorMessage name='newsletterSubscription'>
															{(msg) => (
																<div className={appStyles.formError}>You must accept the terms and conditions</div>
															)}
														</ErrorMessage>
													</div>
												</div>
											</div>
										</div>*/}
                  </div>

                  <div className={appStyles.formRow}>
                    <div
                      className={`${appStyles.buttonBlock} ${appStyles.buttonBlockNextPrev}`}
                    >
                      <div>
                        <button
                          className={`${appStyles.bfButton} ${appStyles.bfButtonSecondary} ${appStyles.bfButtonOutline} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconBefore}`}
                          type='button'
                          onClick={() => {
                            const _quoteData: ISingleTripQuoteData = {
                              ...quoteData,
                            };
                            _quoteData.all_applicants = values.applicants;
                            setQuoteData(_quoteData);
                            prev();
                          }}
                        >
                          Back
                          <span
                            className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconPrev}`}
                          >
                            <BackArrow />
                          </span>
                        </button>
                      </div>
                      <div className={`${appStyles.paymentButtonWrapper}`}>
                        <button
                          className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconAfter}`}
                          type='submit'
                        >
                          Buy Now
                          {` ${formatAmount(
                            quoteResult?.quoted_price || 0,
                            quoteData.host_country?.code || "US",
                            quoteData.currency?.value || "GBP"
                          )}`}
                          <span
                            className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}
                          >
                            <ForwardArrow />
                          </span>
                        </button>
                        <PaymentIcons />
                      </div>
                    </div>
                  </div>

                  {hasError && (
                    <div className={appStyles.formRow}>
                      <div className={appStyles.battlefaceServerError}>
                        {appConstants.defaultAPIError}
                      </div>
                    </div>
                  )}

                  <Footer />

                  <div className={appStyles.bfMobileFooter}>
                    <div className={appStyles.bfMobileFooterLeft}>
                      <h1 className={appStyles.colorPrimary}>
                        {formatAmount(
                          quoteResult?.quoted_price || 0,
                          quoteData.host_country?.code || "US",
                          quoteData.currency?.value || "GBP"
                        )}
                      </h1>
                      <a
                        className={appStyles.bfbadge}
                        href='#addedBenefits'
                        onClick={(e) => {
                          e.preventDefault();
                          document
                            .getElementById("addedBenefits")
                            ?.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                        }}
                      >
                        {getBenefitsCount()} benefits added
                      </a>
                    </div>

                    <div className={appStyles.bfMobileFooterRight}>
                      <button
                        className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconAfter}`}
                        type='submit'
                      >
                        Buy Now
                        {` ${formatAmount(
                          quoteResult?.quoted_price || 0,
                          quoteData.host_country?.code || "US",
                          quoteData.currency?.value || "GBP"
                        )}`}
                        <span
                          className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}
                        >
                          <ForwardArrow />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <QuoteSummary pay={true} />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default OrderForm;
