import { FC, createContext, useEffect, useMemo, useState } from "react";
import QuoteApi from "../api/quote";
import appConstants, {
  addDays,
  calculateAge,
  convertDateToApiFormat,
  formatAmount,
  packageAllowedMaxAges,
  packageBenefits,
  packageDropDownPlaceholders,
  requiredPackages,
} from "../config/app-constants";
import { ISingleTripQuoteData, SelectOption } from "../config/interfaces";
import Step from "./Step";

export interface ISingleTripQuoteContext {
  currentStep: number;
  totalSteps: number;
  next: () => void;
  prev: () => void;
  restart: () => void;
  quoteData: ISingleTripQuoteData;
  setQuoteData: any;
  quoteResult: any;
  setQuoteResult: any;
  order: any;
  setOrder: any;
  hostCountries: any[];
  destinations: any[];
  loader:
    | "master_data"
    | "quote"
    | "packages"
    | "order"
    | "payment"
    | "policy"
    | null;
  setLoader: any;
  packages: any;
  setPackages: any;
  getPackages: (_quoteData: ISingleTripQuoteData) => Promise<any>;
  updateQuoteDataPackages: (
    _packages: any,
    _quoteData: ISingleTripQuoteData
  ) => Promise<any>;
  getQuote: (
    _quoteData: ISingleTripQuoteData,
    useDefaultLevels?: boolean
  ) => Promise<any>;
  tripType: string;
  setDefaultPackages: any;
  defaultPackages: any;
  groupsData: any;
}

export const SingleTripQuoteContext =
  createContext<ISingleTripQuoteContext | null>(null);

interface TripQuoteProps {
  tripType: string;
  setTripType: React.Dispatch<React.SetStateAction<"st" | "amt" | null>>;
}
const INITIAL_QUOTE = {
  host_country: null,
  host_country_state: "",
  currency: {
    label: "GBP",
    value: "GBP",
  },
  destinations: [],
  start_date: new Date(),
  end_date: addDays(new Date(), 2),
  main_applicant: null,
  other_applicants: [],
  all_applicants: [],
  packages: null,
  excess_fee: appConstants.excessFee2,
  affiliate_id: "",
  external_reference_id: "",
  isAbove18: false,
  updateDefaults: true,
};

const TripQuote: FC<TripQuoteProps> = (props: TripQuoteProps) => {
  const { tripType, setTripType } = props;
  const totalSteps: number = 4;
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [quoteData, setQuoteData] = useState<ISingleTripQuoteData>(
    () => INITIAL_QUOTE
  );
  const [quoteResult, setQuoteResult] = useState<any>(null);
  const [order, setOrder] = useState<any>(null);
  const [loader, setLoader] = useState<
    "master_data" | "quote" | "packages" | "order" | "payment" | "policy" | null
  >("master_data");
  const [destinations, setDestinations] = useState<Array<any>>([]);
  const [hostCountries, setHostCountries] = useState<Array<any>>([]);
  const [packages, setPackages] = useState<Array<any>>([]);
  const [defaultPackages, setDefaultPackages] = useState<any>();
  const [groupsData, setGroupData] = useState<any>();

  useEffect(() => {
    init();
  }, []);

  const productId = useMemo(() => {
    if (tripType === appConstants.ST) {
      return appConstants.singleTripProductId;
    } else {
      return appConstants.multiTripProductId;
    }
  }, [tripType]);

  const init = async () => {
    try {
      const _quoteData: ISingleTripQuoteData = { ...quoteData };
      // setting default excess fee
      _quoteData.excess_fee =
        tripType === appConstants.ST
          ? appConstants.excessFee2
          : appConstants.multiExcessFee1;
      const queryString = window.location.search;
      // affiliated values always available either user pass or not
      const urlParams = new URLSearchParams(queryString);

      _quoteData.affiliate_id = urlParams.get("afid") || appConstants.affiliatedId!;
      _quoteData.external_reference_id = urlParams.get("reference") || "";

      const hcResponse: any = await QuoteApi.getHostCountries(productId!);

      setHostCountries(hcResponse?.data || []);
      if (hcResponse?.data?.length === 1) {
        _quoteData.host_country = hcResponse?.data[0];
      }

      setQuoteData(() => _quoteData);
      const dcResponse: any = await (tripType === appConstants.AMT
        ? QuoteApi.getAMTDestinations(productId!)
        : QuoteApi.getSTDestinations(productId!));
      setDestinations(() => {
        if (tripType === appConstants.AMT) {
          return (dcResponse?.data || []).sort((a: any, b: any) =>
            a.description.localeCompare(b?.description)
          );
        } else {
          return (dcResponse?.data || [])
            .sort((a: any, b: any) => a.name.localeCompare(b.name))
            .filter((item: any) => !item.is_disabled);
        }
      });

      if (tripType === appConstants.AMT) {
        let _allAmtGroupsCountriesAPI: Promise<any>[] = [];

        let _groups: any = {};
        for (let i = 0; i < dcResponse?.data.length; i++) {
          _allAmtGroupsCountriesAPI.push(
            QuoteApi.getAMTDestinationsCountries(
              productId!,
              dcResponse.data[i]?.name
            )
          );
        }
        const _amtDestination = await Promise.allSettled([
          ..._allAmtGroupsCountriesAPI,
        ]);

        _amtDestination
          .filter(
            (_group: any) =>
              _group.status === "fulfilled" && _group.value.data?.name
          )
          .forEach(
            (_group: any) =>
              (_groups[_group.value.data.name] = _group.value.data)
          );

        setGroupData(_groups);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(null);
    }
  };

  const next = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const getPackages = async (_quoteData: ISingleTripQuoteData) => {
    const pkgResponse: any = await QuoteApi.getHostCountryPackages(
      productId!,
      _quoteData.host_country?.code || null
    );

    if (pkgResponse.error) {
      throw pkgResponse;
    }

    const oldestApplicantAgeWhenTripEnds = Math.max(
      ...[_quoteData.main_applicant]
        .concat(_quoteData.other_applicants)
        .map((dob: any) => {
          return calculateAge(dob, _quoteData.start_date).years;
        })
    );
    return (
      pkgResponse?.data
        ?.filter((pkg: any) => {
          if (
            !packageAllowedMaxAges[pkg?.alias] ||
            packageAllowedMaxAges[pkg?.alias] > oldestApplicantAgeWhenTripEnds
          ) {
            return true;
          }
          return false;
        })
        .map((pkg: any) => {
          pkg.benefit =
            (tripType === appConstants.ST && packageBenefits[pkg.alias]) || "";

          if (pkg?.levels) {
            const isRequired: any =
              pkg?.validations?.find((elem: any) => {
                return (
                  elem?.product_package_validation_type?.name === "Is Required"
                );
              }) || requiredPackages.includes(pkg.alias);

            const defaultOption: Array<any> = [];

            if (!isRequired && pkg?.levels?.length > 1) {
              defaultOption.push({
                id: 0,
                level:
                  packageDropDownPlaceholders[pkg?.alias] || "Not included",
              });
            }

            pkg.levels = defaultOption.concat(
              (tripType === appConstants.ST && pkg?.levels?.length > 1
                ? pkg.levels.filter((option: any) => !option?.is_default)
                : pkg.levels
              )?.map((option: any) => {
                switch (option.type) {
                  case "package":
                    return {
                      ...option,
                      level: `Package ${option.level}`,
                    };
                  case "day":
                    return {
                      ...option,
                      level: `${option.level} Days`,
                    };
                  default:
                    const amount = formatAmount(
                      Number(option?.level) || 0,
                      quoteData.host_country?.code || "US",
                      quoteData.currency?.value || "GBP",
                      0,
                      0
                    );
                    return { ...option, level: amount };
                }
              })
            );
          }

          return pkg;
        }) || []
    );
  };

  const updateQuoteDataPackages = async (
    _packages: Array<any>,
    _quoteData: ISingleTripQuoteData
  ) => {
    if (!Object.keys(_quoteData.packages || {}).length) {
      const _selectedPackages: any = {};
      _packages.forEach((pkg: any) => {
        const isRequired: any =
          pkg?.validations?.find((elem: any) => {
            return (
              elem?.product_package_validation_type?.name === "Is Required"
            );
          }) || requiredPackages.includes(pkg.alias);

        if (isRequired) {
          if (pkg?.levels?.length) {
            _selectedPackages[pkg.alias] = {
              package: pkg,
              level: pkg.levels[0],
              defaultLevel: pkg.levels[0],
            };
          } else {
            _selectedPackages[pkg.alias] = {
              package: pkg,
              level: {
                id: 1, // in this case only package id will be sent in the quote request instead of an object
              },
              defaultLevel: {
                id: 1, // in this case only package id will be sent in the quote request instead of an object
              },
            };
          }
        }
      });
      _quoteData.packages = _selectedPackages;
    }
    return _quoteData;
  };

  const getQuote = async (
    _quoteData: ISingleTripQuoteData,
    useDefaultLevels: boolean = false
  ) => {
    const apiData: any = {
      start_date: convertDateToApiFormat(_quoteData.start_date),
      end_date: convertDateToApiFormat(_quoteData.end_date),
      product:
        tripType === appConstants.ST
          ? appConstants.singleTripProductId
          : appConstants.multiTripProductId,
      host_country: _quoteData.host_country?.code,
      currency: _quoteData.currency?.value,
      packages: Object.values(_quoteData.packages || {})
        ?.filter((pkg: any) =>
          !useDefaultLevels ? pkg.level?.id : pkg.defaultLevel?.id
        )
        ?.map((pkg: any) => {
          if (useDefaultLevels) {
            return pkg.defaultLevel.id === 1 // in case of level with id 1 (added locally in updateQuoteDataPackages function, we will only send package id)
              ? pkg.package.id
              : {
                  id: pkg.package.id,
                  levels: [
                    {
                      id: pkg.defaultLevel.id,
                    },
                  ],
                };
          } else {
            return pkg.level.id === 1 // in case of level with id 1 (added locally in updateQuoteDataPackages function, we will only send package id)
              ? pkg.package.id
              : {
                  id: pkg.package.id,
                  levels: [
                    {
                      id: pkg.level.id,
                    },
                  ],
                };
          }
        }),
      applicants: [
        {
          is_main: true,
          dob: convertDateToApiFormat(_quoteData.main_applicant),
          trip_cost: 1,
        },
      ].concat(
        _quoteData.other_applicants
          ?.filter((dob: Date | null) => dob != null)
          .map((dob: Date | null) => {
            return {
              is_main: false,
              dob: convertDateToApiFormat(dob),
              trip_cost: 1,
            };
          })
      ),
      deductibles: {
        excess_fee: _quoteData.excess_fee,
      },
    };

    if (tripType === appConstants.AMT) {
      apiData.destination_groups = _quoteData.destinations?.map(
        (dest: SelectOption) => {
          return dest?.name;
        }
      );
    }
    if (tripType === appConstants.ST) {
      apiData.destinations = _quoteData.destinations?.map(
        (dest: SelectOption) => {
          return dest?.code;
        }
      );
    }
    const quoteResponse: any = await QuoteApi.getQuickQuote(apiData);

    if (quoteResponse.error) {
      throw quoteResponse;
    }

    quoteResponse.data.details = (quoteResponse?.data?.details || []).map(
      (quote: any) => {
        quote.expanded = false;
        return quote;
      }
    );
    return quoteResponse?.data || null;
  };

  const restart = () => {
    setCurrentStep(0);
    setQuoteResult(null);
    setOrder(null);
    setQuoteData(() => INITIAL_QUOTE);
    setPackages([]);
    setTripType(null);
  };

  return (
    <SingleTripQuoteContext.Provider
      value={{
        currentStep,
        totalSteps,
        next,
        prev,
        quoteData,
        setQuoteData,
        restart,
        quoteResult,
        setQuoteResult,
        order,
        setOrder,
        hostCountries,
        destinations,
        loader,
        setLoader,
        packages,
        setPackages,
        getPackages,
        updateQuoteDataPackages,
        getQuote,
        tripType,
        setDefaultPackages,
        defaultPackages,
        groupsData,
      }}
    >
      <Step />
    </SingleTripQuoteContext.Provider>
  );
};

export default TripQuote;
