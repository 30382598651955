import appConstants from "../config/app-constants";

export default class QuoteApi {
  static getHostCountries = async (productId: string) => {
    const response = await fetch(
      `${appConstants.apiUrl}/product/${productId}/host-countries`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  };

  static getSTDestinations = async (productId: string) => {
    const response = await fetch(
      `${appConstants.apiUrl}/affiliate/product/${productId}/destination-countries`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  };

  static getAMTDestinations = async (productId: string) => {
    const response = await fetch(
      `${appConstants.apiUrl}/products/${productId}/destination/groups`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  };

  static getAMTDestinationsCountries = async (
    productId: string,
    groups: string
  ) => {
    const response = await fetch(
      `${appConstants.apiUrl}/products/${productId}/destination/groups/minified/${groups}?getExcluded=1`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  };

  static getHostCountryPackages = async (
    productId: string,
    countryCode: string | null
  ) => {
    const response = await fetch(
      `${appConstants.apiUrl}/product/${productId}/country/${countryCode}/packages`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  };

  static getQuickQuote = async (data: any) => {
    const response = await fetch(`${appConstants.apiUrl}/quote`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.json();
  };

  static createOrder = async (data: any) => {
    const response = await fetch(`${appConstants.apiUrl}/orders/condensed`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.json();
  };

  static getPaymentIntent = async (orderId: string) => {
    const response = await fetch(
      `${appConstants.apiUrl}/orders/${orderId}/payment/intent`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  };

  static successProcessPayment = async (orderId: string) => {
    const response = await fetch(
      `${appConstants.apiUrl}/orders/${orderId}/payment/status`,
      {
        method: "POST",
        body: JSON.stringify({
          status: "Approved",
          generate_docs: true,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  };

  static downloadPolicyFile = async (policyNumber: string) => {
    const response = await fetch(
      `${appConstants.apiUrl}/policies/${policyNumber}/download`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  };

  static getPolicyFiles = async (policyId: string) => {
    const response = await fetch(
      `${appConstants.apiUrl}/policies/${policyId}/files`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  };
}
