import { FC } from "react";

interface ClearCircleProps {}

const ClearCircle: FC<ClearCircleProps> = (props: ClearCircleProps) => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0 10.5C0 4.99219 4.45312 0.5 10 0.5C15.5078 0.5 20 4.99219 20 10.5C20 16.0469 15.5078 20.5 10 20.5C4.45312 20.5 0 16.0469 0 10.5ZM6.83594 8.66406L8.67188 10.5L6.83594 12.3359C6.44531 12.7266 6.44531 13.3125 6.83594 13.6641C7.1875 14.0547 7.77344 14.0547 8.125 13.6641L9.96094 11.8281L11.8359 13.6641C12.1875 14.0547 12.7734 14.0547 13.125 13.6641C13.5156 13.3125 13.5156 12.7266 13.125 12.3359L11.2891 10.5L13.125 8.66406C13.5156 8.3125 13.5156 7.72656 13.125 7.33594C12.7734 6.98438 12.1875 6.98438 11.8359 7.33594L9.96094 9.21094L8.125 7.33594C7.77344 6.98438 7.1875 6.98438 6.83594 7.33594C6.44531 7.72656 6.44531 8.3125 6.83594 8.66406Z' />
    </svg>
  );
};

export default ClearCircle;
