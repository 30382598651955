import { FC } from "react";
import appStyles from "../../App.module.css";

interface Props {}

const TravelInsurance: FC<Props> = (props: Props) => {
  return (
    <div className={`${appStyles.borderBox} ${appStyles.quoteBottomInfo}`}>
      <h5>FCDO Travel Insurance Cover</h5>
      <p>
        <small>
          {/* This insurance product covers destinations worldwide, including
          countries under FCDO and government essential and non-essential travel
          advisories. For the latest information on FCDO travel advice&nbsp; */}
          This insurance product covers travel to countries under FCDO and
          government essential and non-essential travel advisories. For the
          latest information on FCDO travel advice&nbsp;
          <a
            href='https://www.gov.uk/foreign-travel-advice'
            className={`${appStyles.textWhite}`}
            target='_blank'
            rel='noreferrer'
          >
            click here
          </a>
          .
        </small>
      </p>
    </div>
  );
};

export default TravelInsurance;
