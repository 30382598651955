import { FC } from "react";

interface CalendarProps {}

const Calendar: FC<CalendarProps> = (props: CalendarProps) => {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M3.5 1.625C3.5 1.16016 3.88281 0.75 4.375 0.75C4.83984 0.75 5.25 1.16016 5.25 1.625V2.5H8.75V1.625C8.75 1.16016 9.13281 0.75 9.625 0.75C10.0898 0.75 10.5 1.16016 10.5 1.625V2.5H11.8125C12.5234 2.5 13.125 3.10156 13.125 3.8125V5.125H0.875V3.8125C0.875 3.10156 1.44922 2.5 2.1875 2.5H3.5V1.625ZM13.125 13.4375C13.125 14.1758 12.5234 14.75 11.8125 14.75H2.1875C1.44922 14.75 0.875 14.1758 0.875 13.4375V6H13.125V13.4375Z' />
    </svg>
  );
};

export default Calendar;
