import CookieConsent from "react-cookie-consent";
import appStyles from "../App.module.css";
import appConstants from "../config/app-constants";
const CookieConsents = () => {
  return (
    <CookieConsent
      location='bottom'
      buttonText='I Understand'
      disableStyles={false}
      overlay={true}
      cookieName='cookie-consent'
      debug={appConstants.cookieConsentDebug}
      expires={150}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "#f9f9f9",
        maxWidth: "476px",
        width: "100%",
        height: "280px",
        padding: "30px",
        border: "1px solid #d4d8df",
        borderRadius: "15px",
        boxShadow: "0 -1px 10px 0 rgba(172,171,171,.3)",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        top: "30%",
        zIndex: "9999",
      }}
      buttonStyle={{
        display: "block",
        background: "#191c1f",
        width: "200px",
        fontSize: "15px",
        fontWeight: "700",
        color: "#fff",
        padding: "0.5rem 1rem",
        borderRadius: "10px",
        borderColor: "#191c1f",
      }}
    >
      <div style={{}}>
        <h1 className={`${appStyles.cookiesModelHeading}`}>COOKIES!</h1>
        <p className={`${appStyles.cookiesModelDetail}`}>
          We love cookies! And no, we’re not talking about Grandma’s yummy
          baking. Cookies help us make sure that your experience stays personal,
          relevant, and most of all, secure. We only use cookies that are
          necessary for your purchase experience.
        </p>
      </div>
    </CookieConsent>
  );
};

export default CookieConsents;
