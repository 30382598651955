import { FC, useContext, useState } from "react";
import Select from "react-select";
import appStyles from "../../App.module.css";
import appConstants, {
  AdditionalPackages,
  activitiesPackageDocs,
  customPackageDescriptions,
  packageDropDownPlaceholders,
  requiredPackages,
  selectControlStyling,
} from "../../config/app-constants";
import { ISingleTripQuoteData } from "../../config/interfaces";
import { ISingleTripQuoteContext, SingleTripQuoteContext } from "../TripQuote";
import PackageDisplayName from "../common/PackageDisplayName";

interface PackageSelectorProps {
  pkg: any;
}

const PackageSelector: FC<PackageSelectorProps> = (
  props: PackageSelectorProps
) => {
  const { pkg } = props;
  const { quoteData, setQuoteData, tripType } = useContext(
    SingleTripQuoteContext
  ) as ISingleTripQuoteContext;
  const [expanded, setExpanded] = useState<boolean>(false);

  const isRequired: any =
    pkg?.validations?.find((elem: any) => {
      return elem?.product_package_validation_type?.name === "Is Required";
    }) || requiredPackages.includes(pkg.alias);

  const onCBValueChange = (event: any) => {
    const _quoteData: ISingleTripQuoteData = { ...quoteData };
    if (event.target.checked) {
      _quoteData.packages = _quoteData.packages || {};
      _quoteData.packages[pkg.alias] = {
        package: pkg,
        level: pkg.levels[0],
        defaultLevel: null,
      };
    } else {
      delete _quoteData.packages[pkg.alias];
    }
    setQuoteData(_quoteData);
  };

  const onSelectValueChange = (newLevel: any) => {
    const _quoteData: ISingleTripQuoteData = { ...quoteData };
    if (newLevel.id) {
      _quoteData.packages = _quoteData.packages || {};
      _quoteData.packages[pkg.alias] = {
        package: pkg,
        level: newLevel,
        defaultLevel: pkg.alias === "base_package" ? newLevel : pkg.levels[0], // setting the default level as the selected level for base package but for rest the first level as default level
      };
      if (pkg.alias === "base_package") {
        _quoteData.updateDefaults = true;
      } else {
        _quoteData.updateDefaults = false;
      }
    } else {
      delete _quoteData.packages[pkg.alias];
    }

    setQuoteData(_quoteData);
  };

  return pkg ? (
    <div
      className={`${appStyles.collapseBlock} ${
        isRequired && pkg?.levels?.length <= 1
          ? appStyles.collapseBlockPackageIncluded
          : quoteData?.packages[pkg.alias]
          ? pkg.alias !== "base_package" &&
            quoteData.packages[pkg.alias]?.level?.id !==
              quoteData.packages[pkg.alias]?.defaultLevel?.id &&
            appStyles.collapseBlockPackageAdded
          : ""
      }`}
    >
      <div className={appStyles.collapseHeader}>
        <div
          className={appStyles.collapseHeaderTitle}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M9 0.25C13.957 0.25 18 4.29297 18 9.25C18 14.2422 13.957 18.25 9 18.25C4.00781 18.25 0 14.2422 0 9.25C0 4.29297 4.00781 0.25 9 0.25ZM9 14.3125C9.59766 14.3125 10.125 13.8203 10.125 13.1875C10.125 12.5547 9.59766 12.0625 9 12.0625C8.33203 12.0625 7.875 12.5547 7.875 13.1875C7.875 13.8203 8.36719 14.3125 9 14.3125ZM11.4258 9.32031C12.1992 8.86328 12.6562 8.05469 12.6562 7.21094C12.6562 5.83984 11.5664 4.75 10.125 4.75H8.33203C6.99609 4.75 5.90625 5.83984 5.90625 7.21094C5.90625 7.66797 6.29297 8.05469 6.75 8.05469C7.20703 8.05469 7.59375 7.66797 7.59375 7.21094C7.59375 6.78906 7.91016 6.4375 8.36719 6.4375H10.1602C10.582 6.4375 10.9688 6.78906 10.9688 7.21094C10.9688 7.49219 10.8281 7.73828 10.582 7.87891L8.57812 9.07422C8.29688 9.25 8.15625 9.53125 8.15625 9.8125V10.375C8.15625 10.832 8.54297 11.2188 9 11.2188C9.45703 11.2188 9.84375 10.832 9.84375 10.375V10.3047L11.4258 9.32031Z'
              fill='black'
            />
          </svg>
          <p>
            <PackageDisplayName packageName={pkg?.name} />
            {` ${pkg?.benefit}`}
          </p>
        </div>

        {!isRequired || pkg?.levels?.length > 1 ? (
          <div className={appStyles.collapseHeaderPrice}>
            <h6>
              {pkg?.levels?.length <= 1 ? (
                <div className={appStyles.switchWrapper}>
                  <input
                    type='checkbox'
                    value={pkg?.alias}
                    checked={quoteData?.packages[pkg.alias] ? true : false}
                    onChange={onCBValueChange}
                  />
                  <span className={appStyles.switchLabel}></span>
                </div>
              ) : (
                <Select
                  value={quoteData?.packages[pkg?.alias]?.level || ""}
                  placeholder={
                    packageDropDownPlaceholders[pkg?.alias] || "Not included"
                  }
                  name={pkg?.alias}
                  options={pkg?.levels || []}
                  classNames={{
                    control: selectControlStyling,
                  }}
                  onChange={onSelectValueChange}
                  getOptionLabel={(option: any) => {
                    const { levels } = pkg;
                    const firstIndex = levels.indexOf(option);
                    return tripType === "amt" && firstIndex === 0 && isRequired
                      ? `${option["level"]} included`
                      : `${option["level"]}`;
                  }}
                />
              )}
            </h6>
          </div>
        ) : (
          <></>
        )}
      </div>

      {expanded && (
        <div className={appStyles.collapseBody}>
          {pkg.alias === "activities_package" ? (
            <>
              <h6>
                The Activities and Sports listed in Categories 1, 2 and 3 may be
                covered upon payment of an additional premium.
              </h6>
              <a
                href={`${activitiesPackageDocs.doc1}?${new Date().getTime()}`}
                target='_blank'
                className={`${appStyles.bfLinkButton}`}
                rel='noreferrer'
              >
                Package 1 details
              </a>
              <a
                href={`${activitiesPackageDocs.doc2}?${new Date().getTime()}`}
                target='_blank'
                className={`${appStyles.bfLinkButton}`}
                rel='noreferrer'
              >
                Package 2 details
              </a>
              <a
                href={`${activitiesPackageDocs.doc3}?${new Date().getTime()}`}
                target='_blank'
                className={`${appStyles.bfLinkButton}`}
                rel='noreferrer'
              >
                Package 3 details
              </a>
            </>
          ) : (
            <>
              <h6>
                {/* {isRequired
                  ? AdditionalPackages[pkg.alias]?.description ||
                    customPackageDescriptions[pkg.alias] ||
                    ""
                  : pkg?.coverages[0]?.benefit_type.description ||
                    customPackageDescriptions[pkg.alias] ||
                    ""} */}

                {tripType === appConstants.AMT ? (
                  <>
                    {pkg?.coverages?.map(
                      (benefitType: any) =>
                        benefitType?.benefit_type?.description !== null && (
                          <p key={benefitType?.benefit_type?.id}>
                            {benefitType?.benefit_type?.description}
                          </p>
                        )
                    )}
                  </>
                ) : (
                  <>
                    {isRequired
                      ? AdditionalPackages[pkg.alias]?.description ||
                        customPackageDescriptions[pkg.alias] ||
                        ""
                      : pkg?.coverages[0]?.benefit_type.description ||
                        customPackageDescriptions[pkg.alias] ||
                        ""}
                  </>
                )}
              </h6>
            </>
          )}
        </div>
      )}
    </div>
  ) : null;
};

export default PackageSelector;
