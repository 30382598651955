import { FC } from "react";

interface ForwardArrowProps {}

const ForwardArrow: FC<ForwardArrowProps> = (props: ForwardArrowProps) => {
  return (
    <svg
      width='14'
      height='11'
      viewBox='0 0 14 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M12.8516 6.37891L8.47656 10.7539C8.3125 10.918 8.09375 11 7.875 11C7.62891 11 7.41016 10.918 7.24609 10.7539C6.89062 10.4258 6.89062 9.85156 7.24609 9.52344L10.1172 6.625L1.75 6.625C1.25781 6.625 0.875 6.24219 0.875 5.75C0.875 5.28516 1.25781 4.875 1.75 4.875L10.1172 4.875L7.24609 2.00391C6.89062 1.67578 6.89062 1.10156 7.24609 0.773438C7.57422 0.417969 8.14844 0.417969 8.47656 0.773438L12.8516 5.14844C13.207 5.47656 13.207 6.05078 12.8516 6.37891Z' />
    </svg>
  );
};

export default ForwardArrow;
