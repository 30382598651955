import { FC } from "react";

import appStyles from "../../App.module.css";

type Props = {};

const HeaderImage: FC<Props> = (props: Props) => {
  return (
    <div className={appStyles.footerLogosWrapper}>
      <div className={appStyles.partnerLogoWrapper}>
        <svg
          width='106'
          height='25'
          viewBox='0 0 106 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12.435 6.55C13.0395 4.975 13.0395 3.575 12.2623 2.9625C10.967 1.9125 8.11731 3.4 5.78574 6.2875C3.45417 9.175 2.59063 12.325 3.79959 13.375C5.09491 14.425 7.9446 12.9375 10.2762 10.05C10.3625 9.9625 10.3625 9.9625 10.4489 9.875L10.2762 9.7875C8.54908 11.8 6.47658 12.85 5.61303 12.15C4.66313 11.3625 5.44032 8.9125 7.25376 6.6375C9.06721 4.3625 11.3124 3.1375 12.2623 3.925C12.7804 4.275 12.7804 5.325 12.435 6.55Z'
            fill='black'
          />
          <path
            d='M9.15357 4.7125C8.46274 4.45 7.68555 4.3625 6.99471 4.275C7.51284 4.3625 8.03096 4.45 8.54909 4.625C11.0534 5.325 12.8668 6.8125 12.5214 7.775C12.176 8.825 9.84441 9 7.34013 8.3C4.83585 7.6 3.02241 6.1125 3.36783 5.15C3.45418 4.8 3.88595 4.5375 4.49043 4.3625C3.54054 4.5375 2.8497 4.975 2.67699 5.5875C2.24522 6.9875 4.40408 8.9125 7.59919 9.875C10.7079 10.8375 13.644 10.4875 14.0758 9.0875C14.5075 7.6 12.3487 5.675 9.15357 4.7125Z'
            fill='black'
          />
          <path
            d='M7.9446 15.9125C3.79959 15.9125 0.431772 12.5 0.431772 8.3C0.431772 4.1 3.79959 0.6875 7.9446 0.6875C12.0896 0.6875 15.4574 4.1 15.4574 8.3C15.4574 12.5875 12.0896 15.9125 7.9446 15.9125ZM7.9446 0.25C3.54053 0.25 0 3.925 0 8.3C0 12.7625 3.54053 16.35 7.9446 16.35C12.3487 16.35 15.8892 12.7625 15.8892 8.3C15.8892 3.925 12.3487 0.25 7.9446 0.25Z'
            fill='black'
          />
          <path
            d='M26.5971 9.0875C26.5108 7.8625 25.6472 6.9 24.3519 6.9C22.9703 6.9 22.1931 7.8625 22.1067 9.0875H26.5971ZM27.8061 11.275C27.4607 12.9375 26.1654 13.9 24.4383 13.9C21.934 13.9 20.725 12.2375 20.6387 9.875C20.6387 7.6 22.1931 5.85 24.3519 5.85C27.1153 5.85 27.9788 8.3875 27.8925 10.225H22.0204C21.934 11.5375 22.7112 12.7625 24.4383 12.7625C25.4745 12.7625 26.2517 12.2375 26.5108 11.275'
            fill='black'
          />
          <path
            d='M36.1825 13.6375H34.9735V12.4125C34.4554 13.375 33.5055 13.8125 32.3829 13.8125C30.3104 13.8125 29.7059 12.675 29.7059 11.0125V5.9375H31.0012V11.1875C31.0012 12.15 31.6057 12.7625 32.642 12.7625C34.1963 12.7625 34.9735 11.7125 34.9735 10.3125V5.9375H36.2688'
            fill='black'
          />
          <path
            d='M38.5141 5.9375H39.723V7.6C40.3275 6.375 41.191 5.7625 42.6591 5.7625V7.075C40.5866 7.075 39.8094 8.2125 39.8094 10.225V13.6375H38.5141'
            fill='black'
          />
          <path
            d='M47.1495 12.7625C48.5312 12.7625 49.5674 11.7125 49.5674 9.875C49.5674 8.0375 48.4448 6.9875 47.1495 6.9875C45.7678 6.9875 44.7316 8.0375 44.7316 9.875C44.6452 11.7125 45.7678 12.7625 47.1495 12.7625ZM47.1495 5.7625C49.6538 5.7625 50.9491 7.5125 50.9491 9.7875C50.9491 12.0625 49.6538 13.8125 47.1495 13.8125C44.6452 13.8125 43.3499 12.0625 43.3499 9.7875C43.2635 7.6 44.6452 5.7625 47.1495 5.7625Z'
            fill='black'
          />
          <path
            d='M58.2029 8.475C58.0301 7.5125 57.3393 6.9 56.2167 6.9C54.3169 6.9 53.7988 8.3875 53.7988 9.875C53.7988 11.275 54.4896 12.675 56.1304 12.675C57.3393 12.675 58.1165 11.975 58.2892 10.8375H59.5845C59.3255 12.675 58.1165 13.8125 56.1304 13.8125C53.7124 13.8125 52.4171 12.15 52.4171 9.875C52.4171 7.6 53.6261 5.7625 56.1304 5.7625C57.9438 5.7625 59.3255 6.55 59.4982 8.3875'
            fill='black'
          />
          <path
            d='M61.398 3.05H62.6933V7.1625C63.125 6.2 64.2477 5.85 65.1976 5.85C67.2701 5.85 67.8745 6.9875 67.8745 8.65V13.725H66.5792V8.475C66.5792 7.5125 65.9747 6.9 64.9385 6.9C63.3841 6.9 62.6069 7.95 62.6069 9.35V13.725H61.3116'
            fill='black'
          />
          <path
            d='M75.2147 9.7C74.6965 10.05 73.7466 10.05 72.8831 10.225C72.0196 10.4 71.3287 10.6625 71.3287 11.625C71.3287 12.5 72.0196 12.7625 72.8831 12.7625C74.6102 12.7625 75.301 11.7125 75.301 11.0125V9.7H75.2147ZM77.2872 13.6375C77.0281 13.8125 76.769 13.8125 76.3373 13.8125C75.6464 13.8125 75.2147 13.4625 75.2147 12.5875C74.5238 13.375 73.5739 13.8125 72.4513 13.8125C70.9833 13.8125 69.8607 13.2 69.8607 11.625C69.8607 9.875 71.156 9.525 72.5377 9.2625C74.0057 9 75.2147 9.0875 75.2147 8.125C75.2147 6.9875 74.2648 6.8125 73.4876 6.8125C72.365 6.8125 71.5878 7.1625 71.5014 8.3H70.2061C70.2925 6.375 71.7605 5.7625 73.5739 5.7625C74.9556 5.7625 76.51 6.1125 76.51 7.8625V11.8C76.51 12.4125 76.51 12.675 76.9418 12.675C77.0281 12.675 77.2008 12.675 77.3735 12.5875'
            fill='black'
          />
          <path
            d='M78.8415 5.9375H80.0505V7.1625C80.5686 6.2 81.5185 5.7625 82.6411 5.7625C84.7136 5.7625 85.3181 6.9 85.3181 8.5625V13.6375H84.0228V8.3875C84.0228 7.425 83.4183 6.8125 82.3821 6.8125C80.8277 6.8125 80.0505 7.8625 80.0505 9.2625V13.6375H78.7552'
            fill='black'
          />
          <path
            d='M93.2627 9.7C93.2627 8.3 92.6582 6.9 91.0175 6.9C89.3768 6.9 88.6859 8.2125 88.6859 9.7C88.6859 11.1 89.2041 12.675 90.9312 12.675C92.6582 12.675 93.2627 11.1 93.2627 9.7ZM94.558 13.025C94.558 15.5625 93.3491 16.7875 90.9312 16.7875C89.4631 16.7875 87.736 16.175 87.6497 14.5125H88.945C89.0314 15.475 90.154 15.7375 91.0175 15.7375C92.7446 15.7375 93.3491 14.5125 93.3491 12.85V12.325C92.9173 13.2875 91.8811 13.725 90.9312 13.725C88.5996 13.725 87.3906 11.8875 87.3906 9.7875C87.3906 7.95 88.3405 5.675 91.0175 5.675C91.9674 5.675 92.9173 6.1125 93.3491 6.9875V5.85H94.558'
            fill='black'
          />
          <path
            d='M102.33 9.0875C102.244 7.8625 101.38 6.9 100.085 6.9C98.7031 6.9 97.9259 7.8625 97.8395 9.0875H102.33ZM103.625 11.275C103.28 12.9375 101.985 13.9 100.257 13.9C97.7532 13.9 96.5442 12.2375 96.4578 9.875C96.4578 7.6 98.0122 5.85 100.171 5.85C102.934 5.85 103.798 8.3875 103.712 10.225H97.8395C97.7532 11.5375 98.5303 12.7625 100.257 12.7625C101.294 12.7625 102.071 12.2375 102.33 11.275'
            fill='black'
          />
          <path
            d='M2.41792 23.7C2.0725 24.4875 1.81344 24.75 1.20896 24.75C1.03625 24.75 0.863539 24.75 0.777185 24.75V24.1375C0.863539 24.1375 1.03625 24.225 1.20896 24.225C1.46802 24.225 1.64073 24.05 1.72708 23.875L1.98615 23.175L0.345413 19.0625H1.1226L2.33156 22.3875L3.45417 19.0625H4.14501'
            fill='black'
          />
          <path
            d='M6.47658 22.65C7.16741 22.65 7.77189 22.125 7.77189 21.075C7.77189 20.1125 7.16741 19.5 6.47658 19.5C5.78574 19.5 5.18126 20.025 5.18126 21.075C5.26761 22.125 5.78574 22.65 6.47658 22.65ZM6.47658 18.975C7.77189 18.975 8.46273 19.9375 8.46273 21.1625C8.46273 22.3875 7.77189 23.35 6.47658 23.35C5.18126 23.35 4.49043 22.3875 4.49043 21.1625C4.57678 19.85 5.18126 18.975 6.47658 18.975Z'
            fill='black'
          />
          <path
            d='M12.6941 23.175H12.0896V22.5625C11.8306 23.0875 11.3124 23.35 10.7943 23.35C9.75805 23.35 9.41263 22.7375 9.41263 21.8625V19.15H10.1035V21.95C10.1035 22.475 10.4489 22.7375 10.967 22.7375C11.7442 22.7375 12.176 22.2125 12.176 21.425V19.0625H12.8668'
            fill='black'
          />
          <path
            d='M13.644 19.0625H14.2485V19.9375C14.5939 19.2375 15.0257 18.975 15.7165 18.975V19.675C14.6802 19.675 14.2485 20.2875 14.2485 21.3375V23.175H13.5576'
            fill='black'
          />
          <path
            d='M18.8253 19.5875H18.1344V18.975H18.8253V18.3625C18.8253 17.6625 19.257 17.3125 19.9479 17.3125C20.0342 17.3125 20.2933 17.3125 20.3796 17.4V18.0125C20.2933 18.0125 20.1206 17.925 20.0342 17.925C19.6888 17.925 19.5161 18.0125 19.5161 18.3625V18.975H20.2933V19.5875H19.5161V23.0875H18.8253'
            fill='black'
          />
          <path
            d='M22.7112 22.65C23.402 22.65 24.0065 22.125 24.0065 21.075C24.0065 20.1125 23.402 19.5 22.7112 19.5C22.0204 19.5 21.4159 20.025 21.4159 21.075C21.4159 22.125 22.0204 22.65 22.7112 22.65ZM22.7112 18.975C24.0065 18.975 24.6974 19.9375 24.6974 21.1625C24.6974 22.3875 24.0065 23.35 22.7112 23.35C21.4159 23.35 20.7251 22.3875 20.7251 21.1625C20.7251 19.85 21.4159 18.975 22.7112 18.975Z'
            fill='black'
          />
          <path
            d='M25.4745 19.0625H26.079V19.9375C26.4244 19.2375 26.8562 18.975 27.547 18.975V19.675C26.5108 19.675 26.079 20.2875 26.079 21.3375V23.175H25.3882'
            fill='black'
          />
          <path
            d='M30.8285 20.725C30.8285 20.1125 30.3104 19.5875 29.6196 19.5875C28.9287 19.5875 28.4969 20.1125 28.4969 20.725H30.8285ZM31.433 21.8625C31.2603 22.7375 30.5695 23.2625 29.7059 23.2625C28.4106 23.2625 27.8061 22.3875 27.7198 21.075C27.7198 19.85 28.4969 18.975 29.6196 18.975C31.0876 18.975 31.5194 20.375 31.433 21.3375H28.4969C28.4969 22.0375 28.8424 22.65 29.7923 22.65C30.3104 22.65 30.7422 22.3875 30.8285 21.8625'
            fill='black'
          />
          <path
            d='M32.2965 19.0625H32.9874V23.175H32.2965V19.0625ZM32.9874 18.275H32.2965V17.4875H32.9874'
            fill='black'
          />
          <path
            d='M36.8733 20.9875C36.8733 20.2875 36.5279 19.5 35.7507 19.5C34.8872 19.5 34.5418 20.2 34.5418 20.9875C34.5418 21.6875 34.8008 22.5625 35.6644 22.5625C36.5279 22.5625 36.8733 21.775 36.8733 20.9875ZM37.5642 22.825C37.5642 24.1375 36.9597 24.8375 35.6644 24.8375C34.8872 24.8375 34.0236 24.4875 34.0236 23.6125H34.7145C34.7145 24.1375 35.3189 24.3125 35.7507 24.3125C36.6143 24.3125 36.9597 23.7 36.9597 22.7375V22.475C36.7006 23 36.1825 23.2625 35.6644 23.2625C34.4554 23.2625 33.8509 22.3 33.8509 21.1625C33.8509 20.2 34.2827 18.975 35.7507 18.975C36.2688 18.975 36.7006 19.2375 36.9597 19.675V19.0625H37.5642'
            fill='black'
          />
          <path
            d='M38.6004 19.0625H39.2049V19.675C39.464 19.15 39.9821 18.8875 40.5002 18.8875C41.5365 18.8875 41.8819 19.5 41.8819 20.375V23.0875H41.191V20.375C41.191 19.85 40.8456 19.5875 40.3275 19.5875C39.5503 19.5875 39.1185 20.1125 39.1185 20.9V23.2625H38.4277'
            fill='black'
          />
          <path
            d='M48.013 20.725C48.013 20.1125 47.4949 19.5875 46.8041 19.5875C46.1132 19.5875 45.6815 20.1125 45.6815 20.725H48.013ZM48.6175 21.8625C48.4448 22.7375 47.754 23.2625 46.8904 23.2625C45.5951 23.2625 44.9906 22.3875 44.9043 21.075C44.9043 19.85 45.6815 18.975 46.8041 18.975C48.2721 18.975 48.7039 20.375 48.6175 21.3375H45.5951C45.5951 22.0375 45.9405 22.65 46.8904 22.65C47.4086 22.65 47.8403 22.3875 47.9267 21.8625'
            fill='black'
          />
          <path
            d='M50.5173 20.9875L49.1356 19.0625H49.9992L50.9491 20.4625L51.9853 19.0625H52.7625L51.3809 20.9L52.9352 23.175H52.0717L50.9491 21.425L49.8265 23.175H49.0493'
            fill='black'
          />
          <path
            d='M56.3031 20.375C56.2167 19.85 55.8713 19.5 55.2668 19.5C54.3169 19.5 53.9715 20.2875 53.9715 21.075C53.9715 21.8625 54.3169 22.5625 55.1805 22.5625C55.7849 22.5625 56.2167 22.2125 56.3031 21.6H56.9939C56.8212 22.5625 56.2167 23.175 55.1805 23.175C53.9715 23.175 53.2807 22.3 53.2807 21.075C53.2807 19.85 53.8851 18.8875 55.1805 18.8875C56.1303 18.8875 56.8212 19.325 56.9075 20.2875'
            fill='black'
          />
          <path
            d='M57.7711 17.4875H58.4619V19.675C58.721 19.15 59.2391 18.975 59.7572 18.975C60.7935 18.975 61.1389 19.5875 61.1389 20.4625V23.175H60.4481V20.375C60.4481 19.85 60.1026 19.5875 59.5845 19.5875C58.8073 19.5875 58.3756 20.1125 58.3756 20.9V23.2625H57.6847'
            fill='black'
          />
          <path
            d='M64.6794 20.9875C64.4204 21.1625 63.9022 21.1625 63.4705 21.25C63.0387 21.3375 62.6933 21.5125 62.6933 21.95C62.6933 22.3875 63.0387 22.5625 63.4705 22.5625C64.334 22.5625 64.6794 22.0375 64.6794 21.6V20.9875ZM65.802 23.0875C65.7157 23.175 65.543 23.175 65.2839 23.175C64.9385 23.175 64.6794 23 64.6794 22.5625C64.334 23 63.8159 23.175 63.2114 23.175C62.4342 23.175 61.8297 22.825 61.8297 22.0375C61.8297 21.075 62.5206 20.9 63.2114 20.8125C63.9886 20.6375 64.5931 20.725 64.5931 20.2C64.5931 19.5875 64.075 19.5 63.7295 19.5C63.1251 19.5 62.7796 19.675 62.6933 20.2875H62.1752C62.1752 19.2375 62.9523 18.8875 63.9022 18.8875C64.5931 18.8875 65.4566 19.0625 65.4566 20.025V22.125C65.4566 22.475 65.4566 22.5625 65.6293 22.5625C65.7157 22.5625 65.7157 22.5625 65.802 22.5625'
            fill='black'
          />
          <path
            d='M66.4929 19.0625H67.0974V19.675C67.3564 19.15 67.8745 18.8875 68.3927 18.8875C69.4289 18.8875 69.7743 19.5 69.7743 20.375V23.0875H69.0835V20.375C69.0835 19.85 68.7381 19.5875 68.22 19.5875C67.4428 19.5875 67.011 20.1125 67.011 20.9V23.2625H66.4929'
            fill='black'
          />
          <path
            d='M73.6603 20.9875C73.6603 20.2875 73.3149 19.5 72.5377 19.5C71.6741 19.5 71.3287 20.2 71.3287 20.9875C71.3287 21.6875 71.5878 22.5625 72.4513 22.5625C73.4012 22.5625 73.6603 21.775 73.6603 20.9875ZM74.3511 22.825C74.3511 24.1375 73.7466 24.8375 72.4513 24.8375C71.6741 24.8375 70.8106 24.4875 70.8106 23.6125H71.5014C71.5014 24.1375 72.1059 24.3125 72.5377 24.3125C73.4012 24.3125 73.7466 23.7 73.7466 22.7375V22.475C73.4876 23 72.9695 23.2625 72.4513 23.2625C71.2424 23.2625 70.6379 22.3 70.6379 21.1625C70.6379 20.2 71.0697 18.975 72.5377 18.975C73.0558 18.975 73.4876 19.2375 73.7466 19.675V19.0625H74.3511'
            fill='black'
          />
          <path
            d='M78.2371 20.725C78.2371 20.1125 77.7189 19.5875 77.0281 19.5875C76.3373 19.5875 75.9055 20.1125 75.9055 20.725H78.2371ZM78.8416 21.8625C78.6688 22.7375 77.978 23.2625 77.1145 23.2625C75.8191 23.2625 75.2147 22.3875 75.1283 21.075C75.1283 19.85 75.9055 18.975 77.0281 18.975C78.4961 18.975 78.9279 20.375 78.8416 21.3375H75.8191C75.8191 22.0375 76.1646 22.65 77.1145 22.65C77.6326 22.65 78.0644 22.3875 78.1507 21.8625'
            fill='black'
          />
          <path
            d='M84.7136 20.725C84.7136 20.1125 84.1955 19.5875 83.5047 19.5875C82.8138 19.5875 82.3821 20.1125 82.3821 20.725H84.7136ZM85.3181 21.8625C85.1454 22.7375 84.4546 23.2625 83.591 23.2625C82.2957 23.2625 81.6912 22.3875 81.6049 21.075C81.6049 19.85 82.3821 18.975 83.5047 18.975C84.9727 18.975 85.4045 20.375 85.3181 21.3375H82.2957C82.2957 22.0375 82.6411 22.65 83.591 22.65C84.1092 22.65 84.5409 22.3875 84.6273 21.8625'
            fill='black'
          />
          <path
            d='M87.2179 20.9875L85.8363 19.0625H86.6998L87.6497 20.4625L88.6859 19.0625H89.4631L88.0815 20.9L89.6359 23.175H88.7723L87.6497 21.425L86.5271 23.175H85.6635'
            fill='black'
          />
          <path
            d='M92.1401 19.5C91.2766 19.5 90.9312 20.2875 90.9312 21.075C90.9312 21.8625 91.2766 22.65 92.2265 22.65C93.1764 22.65 93.4354 21.8625 93.4354 21.075C93.4354 20.2875 93.0037 19.5 92.1401 19.5ZM90.2403 19.0625H90.9312V19.5875C91.1902 19.15 91.7084 18.975 92.2265 18.975C93.4354 18.975 94.1263 19.9375 94.1263 21.1625C94.1263 22.3 93.5218 23.2625 92.3128 23.2625C91.8811 23.2625 91.2766 23.0875 91.0175 22.65V24.75H90.3267'
            fill='black'
          />
          <path
            d='M97.7531 20.725C97.7531 20.1125 97.235 19.5875 96.5442 19.5875C95.8534 19.5875 95.4216 20.1125 95.4216 20.725H97.7531ZM98.3576 21.8625C98.1849 22.7375 97.4941 23.2625 96.6305 23.2625C95.3352 23.2625 94.7307 22.3875 94.6444 21.075C94.6444 19.85 95.4216 18.975 96.5442 18.975C98.0122 18.975 98.444 20.375 98.3576 21.3375H95.3352C95.3352 22.0375 95.6806 22.65 96.6305 22.65C97.1487 22.65 97.5804 22.3875 97.6668 21.8625'
            fill='black'
          />
          <path
            d='M99.1348 19.0625H99.7393V19.9375C100.085 19.2375 100.516 18.975 101.207 18.975V19.675C100.171 19.675 99.7393 20.2875 99.7393 21.3375V23.175H99.0485'
            fill='black'
          />
          <path
            d='M102.762 19.0625H103.539V19.675H102.762V22.2125C102.762 22.5625 102.848 22.5625 103.28 22.5625H103.625V23.175H103.107C102.416 23.175 102.157 23 102.157 22.3V19.675H101.466V19.0625H102.157V17.8375H102.848'
            fill='black'
          />
          <path
            d='M104.489 4.975C104.662 4.975 104.834 4.975 104.834 4.8C104.834 4.625 104.748 4.625 104.575 4.625H104.316V4.975H104.489ZM104.316 5.5875H104.143V4.45H104.575C104.834 4.45 104.921 4.5375 104.921 4.8C104.921 4.975 104.834 5.0625 104.662 5.15L105.007 5.675H104.834L104.489 5.15H104.23V5.5875H104.316ZM103.712 4.975C103.712 5.4125 104.057 5.85 104.489 5.85C104.921 5.85 105.266 5.5 105.266 4.975C105.266 4.45 104.921 4.1 104.489 4.1C104.057 4.1875 103.712 4.5375 103.712 4.975ZM105.439 4.975C105.439 5.5 105.007 5.9375 104.489 5.9375C103.971 5.9375 103.539 5.5 103.539 4.975C103.539 4.45 103.971 4.0125 104.489 4.0125C105.007 4.0125 105.439 4.45 105.439 4.975Z'
            fill='black'
          />
        </svg>
      </div>
      <div className={appStyles.poweredbyLogoWrapper}></div>
    </div>
  );
};

export default HeaderImage;
