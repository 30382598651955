import { FC } from "react";
import appStyles from "../../App.module.css";

type Props = {};

const BannerAdImage: FC<Props> = (props: Props) => {
  return (
    <div className={`${appStyles.bannerContainer}`}>
      <a href='https://www.eurochange.co.uk/' target='_blank'>
        <img
          src='https://rebrand2.battleface.com/wp-content/uploads/2023/07/banner_ad.png'
          alt='banner-ad'
        />
      </a>
    </div>
  );
};

export default BannerAdImage;
