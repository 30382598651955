import { FC, Fragment, useContext } from "react";
import appStyles from "../App.module.css";
import {
  ISingleTripQuoteContext,
  SingleTripQuoteContext,
} from "./TripQuote";

type StepperProps = {};

const Stepper: FC<StepperProps> = (props: StepperProps) => {
  const { currentStep, totalSteps } = useContext(
    SingleTripQuoteContext
  ) as ISingleTripQuoteContext;
  const tempArray = new Array(totalSteps).fill(0);
  return (
    <div className={appStyles.progressWrapper}>
      {tempArray.map((value, index) => {
        return (
          <Fragment key={index}>
            <div
              className={`${appStyles.progressDot} ${
                index <= currentStep ? appStyles.progressDone : ""
              }`}
            ></div>
            {index < totalSteps - 1 && (
              <div
                className={`${appStyles.progressLine} ${
                  index <= currentStep - 1 ? appStyles.progressDone : ""
                }`}
              ></div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Stepper;
