import { ErrorMessage, Formik } from "formik";
import { FC, useContext, useState } from "react";
import DatePicker from "react-date-picker";
import Select from "react-select";
import * as yup from "yup";

import appStyles from "../../App.module.css";
import appConstants, {
  AdditionalPackages,
  addDays,
  currencyOptions,
  isValidDate,
  selectControlStyling,
  selectOptionControlStyling,
  subtractMonths,
  totalDaysInCurrentYear,
  validateAge,
} from "../../config/app-constants";
import { IQuoteForm, ISingleTripQuoteData } from "../../config/interfaces";
import InclusionAndExclusionsPopup from "../InclusionAndExclusionsPopup";
import Popup from "../Popup";
import Stepper from "../Stepper";
import TooltipIcon from "../TooltipIcon";
import { ISingleTripQuoteContext, SingleTripQuoteContext } from "../TripQuote";
import Footer from "../common/Footer";
import HeaderImage from "../common/HeaderImage";
import ScrollToFirstError from "../common/ScrollToFirstError";
import Calendar from "../icons/Calender";
import Clear from "../icons/Clear";
import ClearCircle from "../icons/ClearCircle";
import ForwardArrow from "../icons/ForwardArrow";

interface QuoteFormProps {}

const QuoteForm: FC<QuoteFormProps> = (props: QuoteFormProps) => {
  const {
    next,
    quoteData,
    setQuoteData,
    hostCountries,
    loader,
    setLoader,
    setPackages,
    setQuoteResult,
    destinations,
    getPackages,
    updateQuoteDataPackages,
    getQuote,
    tripType,
    setDefaultPackages,
    groupsData,
  } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;
  const commonValidation = yup
    .date()
    .required("This field is required")
    .max(
      subtractMonths(new Date(), 1),
      "Traveller must be at least 1 month old on policy start date."
    );

  const stValidation = commonValidation
    .test("stMainApplicant", "Invalid date entered.", (value) => {
      return isValidDate(value);
    })
    .when("end_date", (end_date: any, schema) => {
      if (end_date && end_date?.length && end_date[0]) {
        return schema.min(
          addDays(end_date[0], -29219),
          "Traveller must not be older than 79 years on policy end date."
        );
      }
      return schema;
    });
  const amtValidation = commonValidation
    .test("amtMainApplicant", "Invalid date entered.", (value: any) => {
      return isValidDate(value);
    })
    .when("start_date", (start_date: any, schema) => {
      if (start_date && start_date?.length && start_date[0]) {
        return schema.min(
          addDays(start_date[0], -27758),
          "Traveller must not be older than 75 years on policy start date."
        );
      }
      return schema;
    });

  const stOtherApplicants = yup
    .array()
    .when("end_date", (end_date: any, schema) => {
      if (end_date && end_date?.length && end_date[0]) {
        return schema.of(
          yup
            .date()
            .required("This field is required")
            .test("stOtherApplicant", "Invalid date entered.", (value: any) => {
              return isValidDate(value);
            })
            .max(
              subtractMonths(new Date(), 1),
              "Traveller must be at least 1 month old on policy start date."
            )
            .min(
              addDays(end_date[0], -29219),
              "Traveller must not be older than 79 years on policy end date."
            )
        );
      }
      return schema.of(
        yup
          .date()
          .required("This field is required")
          .max(
            subtractMonths(new Date(), 1),
            "Traveller must be at least 1 month old on policy start date."
          )
      );
    });

  const amtOtherApplicants = yup
    .array()
    .when("start_date", (start_date: any, schema) => {
      if (start_date && start_date?.length && start_date[0]) {
        return schema.of(
          yup
            .date()
            .required("This field is required")
            .test(
              "amtOtherApplicant",
              "Invalid date entered.",
              (value: any) => {
                return isValidDate(value);
              }
            )
            .max(
              subtractMonths(new Date(), 1),
              "Traveller must be at least 1 month old on policy start date."
            )
            .min(
              addDays(start_date[0], -27758),
              "Traveller must not be older than 75 years on policy start date."
            )
        );
      }
      return schema.of(
        yup
          .date()
          .required("This field is required")
          .max(
            subtractMonths(new Date(), 1),
            "Traveller must be at least 1 month old on policy start date."
          )
      );
    });

  const validationSchema = yup.object().shape({
    host_country: yup.object().required(),
    currency: yup.object().required(),
    start_date: yup.date().required(),
    end_date: yup.date().required(),
    destinations: yup.array().min(1),
    main_applicant:
      tripType === appConstants.AMT ? amtValidation : stValidation,
    other_applicants:
      tripType === appConstants.AMT ? amtOtherApplicants : stOtherApplicants,
    isAbove18: yup
      .boolean()
      .required()
      .oneOf([true], "You must confirm the above statement."),
  });

  const initialFormValues: IQuoteForm = {
    host_country: quoteData.host_country,
    currency: quoteData.currency,
    start_date: quoteData.start_date,
    end_date:
      tripType === appConstants.AMT
        ? addDays(
            new Date(quoteData?.start_date || new Date()),
            totalDaysInCurrentYear(quoteData?.start_date)
          )
        : quoteData.end_date,
    destinations: quoteData.destinations,
    main_applicant: quoteData.main_applicant,
    other_applicants: quoteData.other_applicants,
    isAbove18: quoteData.isAbove18,
  };

  const [hasError, setHasError] = useState<string | null>(null);
  const [showPopup, setSowPopup] = useState<boolean>(false);

  const handleFormSubmit = async (values: IQuoteForm) => {
    try {
      setLoader("quote");

      let _quoteData: ISingleTripQuoteData = {
        ...quoteData,
        packages: null,
        all_applicants: [],
      };
      _quoteData.host_country = values.host_country;
      _quoteData.currency = values.currency;
      _quoteData.start_date = values.start_date;
      _quoteData.end_date = values.end_date;
      _quoteData.destinations = values.destinations;
      _quoteData.main_applicant = values.main_applicant;
      _quoteData.other_applicants = values.other_applicants;
      _quoteData.isAbove18 = values.isAbove18;

      const _packages = await getPackages(_quoteData);

      _quoteData = await updateQuoteDataPackages(_packages, _quoteData);
      const _quoteResult = await getQuote(_quoteData);

      const _defaultPackage: any = {};
      _quoteResult.details.map((packageItem: any) => {
        const { package_alias, package_id, amount, type } = packageItem;
        _defaultPackage[package_alias] = { package_id, amount, type };
      });
      setDefaultPackages(_defaultPackage);

      // Sorting packages but keeping the base package on the top
      setPackages(() =>
        tripType === appConstants.ST
          ? _packages.concat(Object.values(AdditionalPackages))
          : [
              _packages.find((_pkg: any) => _pkg.alias === "base_package"),
              ..._packages
                .filter((_pkg: any) => _pkg.alias !== "base_package")
                .toSorted((a: any, b: any) => {
                  const levelsA = a.levels.length;
                  const levelsB = b.levels.length;

                  if (levelsA > levelsB) {
                    return -1;
                  } else if (levelsA < levelsB) {
                    return 1;
                  }

                  return 0;
                }),
            ]
      );

      setQuoteResult(_quoteResult);
      setQuoteData(_quoteData);
      next();
    } catch (e: any) {
      console.log(e);
      setHasError(e.message);
    } finally {
      setLoader(null);
    }
  };

  let ageConfirmation = null;

  return (
    <>
      {loader === "quote" && (
        <div className={appStyles.bfSpinnerOverlay}>
          <div className={appStyles.bfSpinner}></div>
        </div>
      )}
      <div className={`${appStyles.mainContainer} ${appStyles.W600}`}>
        <div className={appStyles.headerWrapper}>
          <div className={appStyles.pageTitle}>
            <h1 className={`${appStyles.colorPrimary}`}>Get a Quick Quote</h1>
          </div>
          <HeaderImage />
        </div>

        <Stepper />

        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
          enableReinitialize={true}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            handleSubmit,
            submitCount,
            isValid,
            validateForm,
          }) => (
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await validateForm();
                handleSubmit();
              }}>
              <ScrollToFirstError
                submitCount={submitCount}
                isValid={isValid}
                errors={errors}
              />
              <div className={`${appStyles.borderBox} ${appStyles.formBox}`}>
                <div className={appStyles.formWrapper}>
                  <div className={appStyles.pageSubTitle}>
                    <h2 className={appStyles.colorPrimary}>
                      Personal Information
                    </h2>
                  </div>
                  <div className={appStyles.formRow}>
                    <div className={appStyles.formBlock}>
                      <label>
                        What's your country of residence?*
                        <TooltipIcon
                          text={
                            "The country in which you are currently a legal resident."
                          }></TooltipIcon>
                      </label>
                      <div className={appStyles.formRow}>
                        <div
                          className={`${appStyles.formField} ${
                            touched?.host_country && errors?.host_country
                              ? appStyles.hasError
                              : ""
                          }`}>
                          {hostCountries.length === 1 ? (
                            <input
                              type='text'
                              name='host_country'
                              id='host_country'
                              onBlur={handleBlur("host_country")}
                              value={
                                values.host_country
                                  ? values.host_country?.name
                                  : ""
                              }
                              className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                              disabled={true}
                            />
                          ) : (
                            <Select
                              isClearable={true}
                              isSearchable={true}
                              name='host_country'
                              id='host_country'
                              options={hostCountries}
                              isLoading={loader === "master_data"}
                              classNames={{
                                control: selectControlStyling,
                                option: selectOptionControlStyling,
                              }}
                              onBlur={handleBlur("host_country")}
                              onChange={(newValue) => {
                                setFieldValue("host_country", newValue);
                              }}
                              value={values.host_country}
                              getOptionValue={(option: any) =>
                                `${option["code"]}`
                              }
                              getOptionLabel={(option: any) =>
                                `${option["name"]}`
                              }
                            />
                          )}
                          {touched?.host_country && errors?.host_country && (
                            <div className={appStyles.formError}>
                              This field is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={appStyles.formBlock}>
                      <label>
                        What is your home currency?*
                        <TooltipIcon text={"Your local currency"}></TooltipIcon>
                      </label>
                      <div className={appStyles.formRow}>
                        <div
                          className={`${appStyles.formField} ${
                            touched?.currency && errors?.currency
                              ? appStyles.hasError
                              : ""
                          }`}>
                          {hostCountries.length === 1 ? (
                            <input
                              type='text'
                              name='currency'
                              id='currency'
                              value={
                                values.currency ? values.currency?.label : ""
                              }
                              onBlur={handleBlur("currency")}
                              className={`${appStyles.bfFormControl} ${appStyles.bfFormControlSimple}`}
                              disabled={true}
                            />
                          ) : (
                            <Select
                              isClearable={true}
                              isSearchable={true}
                              name='currency'
                              id='currency'
                              options={currencyOptions}
                              classNames={{
                                control: selectControlStyling,
                                option: selectOptionControlStyling,
                              }}
                              onBlur={handleBlur("currency")}
                              onChange={(newValue) => {
                                setFieldValue("currency", newValue);
                              }}
                              defaultValue={values.currency}
                            />
                          )}
                          {touched?.currency && errors?.currency && (
                            <div className={appStyles.formError}>
                              This field is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={appStyles.pageSubTitle}>
                    <h2 className={appStyles.colorPrimary}>Trip Information</h2>
                  </div>
                  <div className={appStyles.formRow}>
                    <label>
                      {tripType === appConstants.ST
                        ? "What are your travel dates?*"
                        : "When do you want cover to start?*"}
                      <TooltipIcon
                        text={
                          tripType === appConstants.AMT
                            ? "The date You want the policy to start. The policy will be valid for 1 year from this date."
                            : "Your travelling dates"
                        }></TooltipIcon>
                    </label>
                  </div>
                  <div className={appStyles.formRow}>
                    <div className={appStyles.formBlock}>
                      <div
                        className={`${appStyles.formField} ${
                          touched?.start_date && errors?.start_date
                            ? appStyles.hasError
                            : ""
                        }`}>
                        <label className={appStyles.formLabelHelper}>
                          {tripType === appConstants.ST && <i>Start Date*</i>}
                        </label>
                        <DatePicker
                          calendarClassName={appStyles.bfpDatePickerCalendar}
                          className={`${appStyles.bfpDatePicker} ${appStyles.bfpDatePickerCalendarStartDate}`}
                          value={values.start_date}
                          format={appConstants.datePickerFormat}
                          maxDate={
                            tripType === appConstants.AMT
                              ? addDays(new Date(), 55)
                              : addDays(new Date(), 365)
                          }
                          dayPlaceholder='dd'
                          monthPlaceholder='mm'
                          yearPlaceholder='yyyy'
                          id='start_date'
                          onChange={(value: any) => {
                            setFieldValue("start_date", value);
                            if (tripType === appConstants.AMT) {
                              setFieldValue(
                                "end_date",
                                addDays(value, totalDaysInCurrentYear(value))
                              );
                            } else {
                              if (
                                !values?.end_date ||
                                values?.end_date < addDays(value, 2)
                              ) {
                                setFieldValue("end_date", addDays(value, 2));
                              }
                            }
                          }}
                          onBlur={handleBlur("start_date")}
                          calendarIcon={() => {
                            return <Calendar />;
                          }}
                          clearIcon={() => {
                            return <Clear />;
                          }}
                          minDate={new Date()}
                        />
                        {touched?.start_date && errors?.start_date && (
                          <div className={appStyles.formError}>
                            This field is required
                          </div>
                        )}
                      </div>
                    </div>
                    {appConstants.ST === tripType && (
                      <div className={appStyles.formBlock}>
                        <div
                          className={`${appStyles.formField} ${
                            touched?.end_date && errors?.end_date
                              ? appStyles.hasError
                              : ""
                          }`}>
                          <label className={appStyles.formLabelHelper}>
                            <i>End Date*</i>
                          </label>
                          <DatePicker
                            calendarClassName={appStyles.bfpDatePickerCalendar}
                            className={`${appStyles.bfpDatePicker} ${appStyles.bfpDatePickerCalendarEndDate}`}
                            format={appConstants.datePickerFormat}
                            value={values.end_date}
                            minDate={addDays(
                              values.start_date || new Date(),
                              2
                            )}
                            maxDate={addDays(
                              values.start_date || new Date(),
                              tripType === appConstants.AMT
                                ? totalDaysInCurrentYear(
                                    values.start_date || new Date()
                                  )
                                : 364
                            )}
                            dayPlaceholder='dd'
                            monthPlaceholder='mm'
                            yearPlaceholder='yyyy'
                            id='end_date'
                            onChange={(value) => {
                              setFieldValue("end_date", value);
                            }}
                            onBlur={handleBlur("end_date")}
                            calendarIcon={() => {
                              return <Calendar />;
                            }}
                            clearIcon={() => {
                              return <Clear />;
                            }}
                            disabled={
                              tripType === appConstants.AMT ? true : false
                            }
                          />
                          {touched?.end_date && errors?.end_date && (
                            <div className={appStyles.formError}>
                              This field is required
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={appStyles.formRow}>
                    <div className={appStyles.formBlock}>
                      <label>
                        {tripType === appConstants.ST
                          ? "What countries are you travelling to?*"
                          : "Where will you be travelling?*"}

                        <TooltipIcon text={"Please select one"}></TooltipIcon>
                      </label>
                      <div className={appStyles.formRow}>
                        <div
                          className={`${appStyles.formField} ${
                            touched?.destinations && errors?.destinations
                              ? appStyles.hasError
                              : ""
                          }`}>
                          <Select
                            defaultValue={values.destinations}
                            isMulti={tripType === appConstants.ST}
                            name='destinations'
                            id='destinations'
                            options={destinations}
                            isLoading={loader === "master_data"}
                            classNames={{
                              control: selectControlStyling,
                            }}
                            onBlur={handleBlur("destinations")}
                            onChange={(newValue) => {
                              const _destination: any =
                                tripType === appConstants.AMT
                                  ? [newValue]
                                  : newValue;

                              setFieldValue("destinations", _destination);
                            }}
                            getOptionValue={(option: any) =>
                              `${
                                option[
                                  tripType === appConstants?.AMT
                                    ? "name"
                                    : "code"
                                ]
                              }`
                            }
                            getOptionLabel={(option: any) =>
                              `${
                                option[
                                  tripType === appConstants.AMT
                                    ? "description"
                                    : "name"
                                ]
                              }`
                            }
                            isOptionDisabled={(option: any) =>
                              option.is_disabled
                            }
                            blurInputOnSelect={false}
                          />
                          {touched?.destinations && errors?.destinations && (
                            <div className={appStyles.formError}>
                              This field is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {tripType === appConstants.AMT &&
                  values.destinations[0]?.name &&
                  groupsData &&
                  groupsData[values.destinations[0]?.name] ? (
                    <div className={appStyles.formRow}>
                      <div className={appStyles.formBlock}>
                        <div
                          className={`${appStyles.pageSubTitle} ${appStyles.disclaimerText}`}>
                          <span className={appStyles.infoIcon}></span>

                          <p>
                            {groupsData[values.destinations[0]?.name]?.name && (
                              <>
                                To see which countries are included or excluded
                                in{" "}
                                {
                                  groupsData[values.destinations[0]?.name]
                                    ?.description
                                }
                                , please{" "}
                                <span
                                  className={appStyles.moreBtn}
                                  onClick={() => {
                                    setSowPopup(true);
                                  }}>
                                  click here
                                </span>
                                .
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className={appStyles.pageSubTitle}>
                    <h2 className={appStyles.colorPrimary}>
                      Traveller(s) Information
                    </h2>
                  </div>
                  <div className={appStyles.formRow}>
                    <div className={appStyles.formBlock}>
                      <label>
                        Please enter your date of birth*
                        <TooltipIcon text={"For lead applicant"}></TooltipIcon>
                      </label>
                      <div className={`${appStyles.formRow}`}>
                        <div
                          className={`${appStyles.formField} ${
                            touched?.main_applicant && errors?.main_applicant
                              ? appStyles.hasError
                              : ""
                          }`}>
                          <DatePicker
                            calendarClassName={appStyles.bfpDatePickerCalendar}
                            className={appStyles.bfpDatePicker}
                            format={appConstants.datePickerFormat}
                            value={values.main_applicant}
                            dayPlaceholder='dd'
                            monthPlaceholder='mm'
                            yearPlaceholder='yyyy'
                            id='main_applicant'
                            onChange={(value) => {
                              setFieldValue("main_applicant", value);
                            }}
                            onKeyDown={(e: any) => {
                              const inputValue = e.target.value;
                              const year = inputValue.slice(-4);
                              if (year.length === 4 && e.key.length === 1) {
                                e.preventDefault();
                              }
                            }}
                            calendarIcon={() => {
                              return <Calendar />;
                            }}
                            clearIcon={() => {
                              return <Clear />;
                            }}
                            disableCalendar={true}
                          />
                          {touched?.main_applicant &&
                            errors?.main_applicant && (
                              <div className={appStyles.formError}>
                                {errors?.main_applicant}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={appStyles.pageSubTitle}>
                    <h4 className={appStyles.colorPrimary}>
                      More people travelling? Please add them below:
                    </h4>
                  </div>
                  <>
                    {values.other_applicants.map(
                      (applicant: Date | null, index: number) => {
                        const hasError =
                          errors?.other_applicants &&
                          errors?.other_applicants[index] &&
                          touched?.other_applicants;
                        return (
                          <div key={index} className={appStyles.formRow}>
                            <div className={appStyles.formBlock}>
                              <div
                                className={appStyles.travellerListFormBlock}
                                key={index}>
                                <div className={appStyles.formRow}>
                                  <div
                                    className={`${appStyles.formField} ${appStyles.additionalApplicantLabel}`}>
                                    Traveller {index + 2}*
                                  </div>
                                  <div
                                    className={`${appStyles.formField} ${
                                      appStyles.fornFieldHasCloseButton
                                    } ${hasError ? appStyles.hasError : ""}`}>
                                    <DatePicker
                                      calendarClassName={
                                        appStyles.bfpDatePickerCalendar
                                      }
                                      className={`${appStyles.bfpDatePicker}`}
                                      format={appConstants.datePickerFormat}
                                      value={values.other_applicants[index]}
                                      dayPlaceholder='dd'
                                      monthPlaceholder='mm'
                                      yearPlaceholder='yyyy'
                                      onChange={(value: any) => {
                                        let _other_applicants =
                                          values.other_applicants.concat();
                                        _other_applicants[index] = value;
                                        setFieldValue(
                                          "other_applicants",
                                          _other_applicants
                                        );
                                      }}
                                      onKeyDown={(e: any) => {
                                        const inputValue = e.target.value;
                                        const year = inputValue.slice(-4);
                                        if (
                                          year.length === 4 &&
                                          e.key.length === 1
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      calendarIcon={() => {
                                        return <Calendar />;
                                      }}
                                      clearIcon={() => {
                                        return <Clear />;
                                      }}
                                      disableCalendar={true}
                                    />
                                    <a
                                      className={appStyles.btnDelete}
                                      href='#'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        let _other_applicants =
                                          values.other_applicants.concat();
                                        _other_applicants.splice(index, 1);
                                        setFieldValue(
                                          "other_applicants",
                                          _other_applicants
                                        );
                                        const _quoteData: ISingleTripQuoteData =
                                          {
                                            ...quoteData,
                                          };
                                        _quoteData.other_applicants?.splice(
                                          index,
                                          1
                                        );
                                        _quoteData.all_applicants?.splice(
                                          index + 1,
                                          1
                                        );
                                        setQuoteData(_quoteData);
                                      }}>
                                      <ClearCircle />
                                    </a>
                                    {hasError ? (
                                      <div className={appStyles.formError}>
                                        {errors?.other_applicants
                                          ? errors?.other_applicants[index]
                                          : ""}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </>
                  <div className={`${appStyles.formRow} ${appStyles.mY15}`}>
                    <div className={appStyles.buttonBlock}>
                      <button
                        className={`${appStyles.bfButton} ${appStyles.bfButtonSecondary} ${appStyles.bfButtonOutline} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconBefore}`}
                        type='button'
                        onClick={() => {
                          let _other_applicants =
                            values.other_applicants.concat();
                          _other_applicants.push(null);
                          setFieldValue("other_applicants", _other_applicants);
                        }}>
                        <span
                          className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconPrev}`}>
                          <svg
                            width='18'
                            height='15'
                            viewBox='0 0 18 15'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path d='M6.375 7.75C4.43359 7.75 2.875 6.19141 2.875 4.25C2.875 2.33594 4.43359 0.75 6.375 0.75C8.28906 0.75 9.875 2.33594 9.875 4.25C9.875 6.19141 8.28906 7.75 6.375 7.75ZM7.74219 9.0625C10.3672 9.0625 12.5 11.1953 12.5 13.8203C12.5 14.3398 12.0625 14.75 11.543 14.75H1.17969C0.660156 14.75 0.25 14.3398 0.25 13.8203C0.25 11.1953 2.35547 9.0625 4.98047 9.0625H7.74219ZM17.0938 6.21875C17.4492 6.21875 17.75 6.51953 17.75 6.875C17.75 7.25781 17.4492 7.53125 17.0938 7.53125H15.7812V8.84375C15.7812 9.22656 15.4805 9.5 15.125 9.5C14.7422 9.5 14.4688 9.22656 14.4688 8.84375V7.53125H13.1562C12.7734 7.53125 12.5 7.25781 12.5 6.875C12.5 6.51953 12.7734 6.21875 13.1562 6.21875H14.4688V4.90625C14.4688 4.55078 14.7422 4.25 15.125 4.25C15.4805 4.25 15.7812 4.55078 15.7812 4.90625V6.21875H17.0938Z' />
                          </svg>
                        </span>
                        Add traveller
                      </button>
                    </div>
                  </div>

                  <div className={appStyles.formRow}>
                    <div className={appStyles.formBlock}>
                      {tripType === appConstants.AMT &&
                      (ageConfirmation = validateAge(
                        values.main_applicant,
                        values.other_applicants,
                        values.start_date
                      )) ? (
                        <div className={appStyles.disclaimerText}>
                          <span className={appStyles.infoIcon}></span>
                          <p>{ageConfirmation}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className={appStyles.formRow}>
                    <div className={`${appStyles.formBlock} ${appStyles.mB0}`}>
                      <div
                        className={`${appStyles.formField} ${
                          errors.isAbove18 &&
                          touched.isAbove18 &&
                          appStyles.hasError
                        }`}>
                        <div className={`${appStyles.bfCheckBox}`}>
                          <label
                            htmlFor='isAbove18'
                            className={appStyles.formLabelHelper}>
                            <input
                              type='checkbox'
                              id='isAbove18'
                              name='isAbove18'
                              value='yes'
                              checked={values.isAbove18}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue("isAbove18", e.target.checked);
                              }}
                            />
                            <span className={`${appStyles.bfCheckmark}`}></span>
                            I confirm that I am at least 18 years of age, or I
                            am the parent/legal guardian of the lead applicant.
                          </label>
                          <ErrorMessage name='isAbove18'>
                            {(msg) => (
                              <div className={appStyles.formError}>{msg}</div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={appStyles.formRow}>
                <div
                  className={`${appStyles.buttonBlock} ${appStyles.buttonBlockNextPrev} ${appStyles.buttonBlockAlignEnd}`}>
                  <button
                    className={`${appStyles.bfButton} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconAfter}`}
                    type='submit'>
                    Get Quote
                    <span
                      className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}>
                      <ForwardArrow />
                    </span>
                  </button>
                </div>
              </div>

              {hasError && (
                <div className={appStyles.formRow}>
                  <div className={appStyles.battlefaceServerError}>
                    {appConstants.defaultAPIError}
                  </div>
                </div>
              )}
              {showPopup && (
                <Popup
                  onClose={() => {
                    setSowPopup(false);
                  }}
                  title='Inclusions and Exclusions'
                  children={
                    <InclusionAndExclusionsPopup
                      groupsData={groupsData}
                      selectedGroups={values.destinations}
                    />
                  }
                />
              )}
            </form>
          )}
        </Formik>

        <Footer />
      </div>
    </>
  );
};
export default QuoteForm;
